
import request from '@/utils/request'
import qs from 'qs'

// 获取pdf文件信息
export const get_pdfinfo = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/online/get_pdfinfo',
    data: qs.stringify(data)
  })
}

export const get_cloudfile_pdf_status = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/online/get_cloudfile_pdf_status',
    data: qs.stringify(data)
  })
}

// 打开云盘PDF
export const open_cloudfile_pdf = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/online/open_cloudfile_pdf',
    data: qs.stringify(data)
  })
}