<template>
  <div class="con-right">
    <div class="con-right-box">
      <div class="ctnr-top">
        <div id="ctnr_top_title" class="ctnr-top-title">CAD转PDF</div>
        <div class="ctnr-top-arrow">▲</div>
      </div>
      <div id="drag_box" class="files-box" style="position: relative">
        <div id="drag_box_type1" v-show="!show_watting_lists">
          <div class="fb-first">
            <img
              id="convert_type_img"
              src="@/assets/images/convert/fileadd_dwg.png"
            />
          </div>
          <div class="fb-second">
            <input
              id="input_file"
              style="
                position: absolute;
                opacity: 0;
                width: 184px;
                height: 50px;
                cursor: pointer;
              "
              type="file"
              multiple="multiple"
              name="file"
              accept=".dwg"
              ref="cadPdfFile"
              @change="cadpdfChange_upload"
            />
            <div class="fb-second-word" @click="upload_dwg()">点击选择文件</div>
          </div>
          <div class="fb-three">或拖放文件至此处</div>
        </div>
        <div id="drag_box_type2" v-show="show_watting_lists">
          <div class="fb-four">
            <div id="files_list">
              <div
                class="fb-four-item"
                v-for="(item, i) in cad_pdf_watting_lists"
                :key="i"
              >
                <div class="img-border">
                  <img
                    class="fbfi-img"
                    :src="convert_status == 'waitstart' ? imgSrc1 : imgSrc2"
                    draggable="false"
                  />
                  <img
                    class="fbfi-img-res"
                    :src="imgStatus2"
                    v-if="item.status == 2"
                  />
                  <img
                    class="fbfi-img-res"
                    :src="imgStatus3"
                    v-if="item.status == 3 || item.status == 4"
                  />
                  <div
                    class="fbfi-x"
                    @click="del_cur_item(i)"
                    v-if="convert_status == 'waitstart'"
                  >
                    <img src="@/assets/images/pay/pay_error.png" />
                  </div>
                </div>
                <div class="res-btn" v-if="convert_status == 'finished'">
                  <div
                    class="download"
                    v-if="item.status == 2"
                    @click="download_file_pdf(item.task_id, item.name)"
                  >
                    下载
                  </div>
                  <div
                    class="retry"
                    v-if="item.status == 3"
                    @click="retry_file_pdf(item.task_id)"
                  >
                    重试
                  </div>
                  <div class="no_tk" v-if="item.status == 4">无图框</div>
                </div>
                <div class="fbfi-name">
                  {{ item.status == 1 ? "正在转换中..." : item.name }}
                </div>
              </div>
            </div>
            <div
              id="input_file_continue_box"
              class="fb-four-item"
              @click="upload_dwg()"
              v-show="convert_status == 'waitstart'"
            >
              <div class="input-file-continue-shadow">
                <img
                  class="fb-second-img"
                  src="@/assets/images/convert/file_add.png"
                  style="width: 50px; height: 50px; margin-top: 50px"
                />
              </div>
              <div id="add_continue" class="add-continue-can-click">
                继续添加
              </div>
            </div>
          </div>
          <div class="fb-five">
            <div class="fb-five-l">
              <div
                class="progress_box"
                style="display: block"
                v-if="convert_status == 'converting'"
              >
                <div id="progress">
                  <div
                    id="cur_progress"
                    :style="'width:' + finished_1 + '%'"
                  ></div>
                </div>
                <div class="progress-num">
                  <span id="cur_progress_num">{{ finished_2 }}</span
                  >%
                </div>
              </div>
              <div class="file_zj">
                共<span id="documents_num">{{ allfiles_num }}</span
                >个文档，<span id="documents_size">{{ allfiles_size }}</span>
              </div>
            </div>
            <div
              id="start_convert"
              class="start-convert-can-click"
              data-type="start"
              @click="cad_pdf_send_btn"
              v-show="convert_status == 'waitstart'"
            >
              开始转换
            </div>
            <div
              id="start_convert"
              class="start-convert-can-click"
              data-type="start"
              @click="cad_pdf_send_btn_quxiao"
              v-show="convert_status == 'converting'"
            >
              取消转换
            </div>
            <div
              id="start_convert"
              class="start-convert-can-click"
              data-type="start"
              @click="cad_pdf_send_btn_quxiao"
              v-show="convert_status == 'finished'"
            >
              继续转换
            </div>
          </div>
        </div>
        <div class="tip" id="tip_file_exist">选中的文件已存在，请检查～</div>
        <div class="tip" id="tip_file_over">
          文件个数超限，请上传不超过4个文件
        </div>
        <div class="tip" id="tip_file_not_dwg">请选择.dwg文件</div>
        <div class="tip" id="tip_file_not_pdf">请选择.pdf文件</div>
      </div>

      <div class="con-right-type con-right-type-cad2pdf" style="display: block">
        <div class="convert-set">
          <div class="cs-title">转换设置</div>
          <div class="cad2pdf cvtype">
            <div class="set-item">
              <div class="cp-title" style="width: auto">转出格式：</div>
              <el-select v-model="value_zcgs" style="margin-left: 20px">
                <el-option
                  v-for="item in options_zcgs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="set-item">
              <div class="cp-title">线条颜色设置：</div>
              <el-radio v-model="cad_pdf_xtys" label="1">彩色</el-radio>
              <el-radio
                v-model="cad_pdf_xtys"
                label="2"
                @change="
                  cad_pdf_xtys == '2' ? (cad_pdf_bgys = '2') : cad_pdf_bgys
                "
                >黑色</el-radio
              >
            </div>
            <div class="set-item">
              <div class="cp-title">图纸背景颜色设置：</div>
              <el-radio
                v-model="cad_pdf_bgys"
                label="1"
                :disabled="cad_pdf_xtys == '2'"
                >黑色</el-radio
              >
              <el-radio v-model="cad_pdf_bgys" label="2">白色</el-radio>
            </div>
            <div class="set-item">
              <div class="cp-title">图纸清晰度设置：</div>
              <el-radio v-model="cad_pdf_qxd" label="1">普通</el-radio>
              <el-radio v-model="cad_pdf_qxd" label="2">高清</el-radio>
              <el-radio v-model="cad_pdf_qxd" label="3">超清</el-radio>
            </div>
            <div class="set-item">
              <div class="cp-title">PDF纸张大小：</div>
              <el-select v-model="value_zzdx">
                <el-option
                  v-for="item in options_zzdx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="set-item">
              <div class="cp-title">纸张方向</div>
              <el-radio v-model="cad_pdf_zzfx" label="1">横向</el-radio>
              <el-radio v-model="cad_pdf_zzfx" label="2">纵向</el-radio>
            </div>
            <div class="set-item">
              <div class="cp-title">文字设置：</div>
              <el-radio v-model="cad_pdf_wzsz" label="1">原生字体</el-radio>
              <el-radio v-model="cad_pdf_wzsz" label="2">图形字体</el-radio>
            </div>
            <div class="set-item">
              <div class="cp-title">线宽设置：</div>
              <el-radio v-model="cad_pdf_xksz" label="1">关闭</el-radio>
              <el-radio v-model="cad_pdf_xksz" label="2">开启</el-radio>
              <span>图纸线宽设置（宽度缩放范围0.1~4倍）：</span>
              <el-input-number
                v-model="cad_pdf_xksz_num"
                size="small"
                :precision="2"
                :step="0.1"
                :max="4"
                :min="0.1"
              ></el-input-number>
            </div>
            <div class="set-item">
              <div class="cp-title">识别图框：</div>
              <el-radio v-model="cad_pdf_sbtk" label="1">是</el-radio>
              <el-radio v-model="cad_pdf_sbtk" label="2">否</el-radio>
            </div>
            <div class="set-item" v-if="cad_pdf_sbtk == 1">
              <div class="cp-title">转出方式：</div>
              <el-radio v-model="cad_pdf_zcfs" label="1"
                >转出多个PDF文件</el-radio
              >
              <el-radio v-model="cad_pdf_zcfs" label="2"
                >合并为一个PDF文件</el-radio
              >
            </div>
            <div class="set-item"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cad_pdf_upload,
  cad_pdf_get_status,
  cad_pdf_retry,
} from "@/api/convert_file.js";
import utils from "@/utils/utils";
import Global from "@/views/dwg/global";

export default {
  name: "CADPDFPage",
  data() {
    return {
      imgSrc1: require("@/assets/images/convert/file_dwg.png"),
      imgSrc2: require("@/assets/images/convert/file_pdf.png"),
      imgStatus2: require("@/assets/images/convert/success.png"),
      imgStatus3: require("@/assets/images/convert/error.png"),
      options_zcgs: [
        {
          value: ".pdf",
          label: ".pdf",
        },
      ],
      value_zcgs: ".pdf",
      options_zzdx: [
        {
          value: "A1",
          label: "A1",
        },
        {
          value: "A2",
          label: "A2",
        },
        {
          value: "A3",
          label: "A3",
        },
        {
          value: "A4",
          label: "A4",
        },
      ],
      value_zzdx: "A4",
      cad_pdf_xtys: "2",
      cad_pdf_bgys: "2",
      cad_pdf_qxd: "1",
      cad_pdf_zzfx: "1",
      cad_pdf_wzsz: "1",
      cad_pdf_xksz: "1",
      cad_pdf_sbtk: "2",
      cad_pdf_zcfs: "2",
      cad_pdf_xksz_num: 0.1,

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      show_watting_lists: false,
      cad_pdf_watting_lists: [],
      tasks: [],
      convert_status: "waitstart",
      timer: null,
      allfiles_num: 0,
      allfiles_size: 0,
      finished_1: 0,
      finished_2: 0,

      task_weight_all: 0,

      is_get_status: false,
    };
  },

  mounted() {
    this.multipleDragEvent();
  },
  methods: {
    multipleDragEvent() {
      let dropbox = document.getElementById("drag_box");
      dropbox.addEventListener("drop", this.eventDrop, false);
      dropbox.addEventListener("dragleave", function (e) {
        e.stopPropagation();
        e.preventDefault();
        // dropbox.style.backgroundColor="#fff"
      });
      dropbox.addEventListener("dragenter", function (e) {
        e.stopPropagation();
        e.preventDefault();
        // dropbox.style.backgroundColor="#fff"
      });
      dropbox.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        // dropbox.style.backgroundColor="#eee"
      });
    },

    eventDrop(e) {
      e.stopPropagation();
      e.preventDefault(); //必填
      this.documentList = [];
      this.documentPath = "";
      let fileData = e.dataTransfer.files;
      if ('finished' == this.convert_status) {
        return
      }
      this.uploadAll(fileData)
    },

    upload_dwg() {
      this.$refs.cadPdfFile.dispatchEvent(new MouseEvent("click"));
    },

    cadpdfChange_upload(e) {
      var files = e.target.files;
      this.uploadAll(files)
    },

    //处理显示列表
    uploadAll(files) {
      var arr = this.cad_pdf_watting_lists;

      if (files.length + arr.length > 4) {
        this.$refs.cadPdfFile.value = "";
        this.$message.error("文件个数超过限制，最大可上传4个文件");
        return false;
      }
      if (arr.length == 0) {
        this.cad_pdf_watting_lists = [...files];
      } else {
        for (var i in files) {
          for (var j in arr) {
            if (arr[j].name == files[i].name) {
              this.$refs.cadPdfFile.value = "";
              this.$message.error("选中的文件已存在，请检查~");
              return false;
            }
          }
        }
        this.cad_pdf_watting_lists = [...arr, ...files];
      }

      for (var y in this.cad_pdf_watting_lists) {
        this.cad_pdf_watting_lists[y].status = 0;
      }

      this.show_watting_lists = true;
      this.$refs.cadPdfFile.value = "";
      this.allfiles_num = this.cad_pdf_watting_lists.length;
      var files_size = 0;
      for (var o in this.cad_pdf_watting_lists) {
        files_size += this.cad_pdf_watting_lists[o].size;
      }
      this.allfiles_size = utils.convert_size_MB(files_size);
    },

    del_cur_item(index) {
      this.cad_pdf_watting_lists.splice(index, 1);

      if (this.cad_pdf_watting_lists.length == 0) {
        this.show_watting_lists = false;
      }
    },

    reset_setting() {
      this.convert_status = "waitstart";
      this.allfiles_num = 0;
      this.allfiles_size = 0;
      this.finished_1 = 0;
      this.finished_2 = 0;
      this.task_weight_all = 0;
      this.is_get_status = false;
      this.cad_pdf_watting_lists = [];
      this.tasks = [];
      clearInterval(this.timer);
    },

    cad_pdf_send_btn_quxiao() {
      this.show_watting_lists = false;
      this.reset_setting();
    },

    reset_files_box() {
      var that = this;
      // var convert_status = that.convert_status

      // if ('waitstart' == convert_status) {

      // } else if ('converting' == convert_status) {

      // } else if ('finished' == convert_status) {

      // }
      var files = that.cad_pdf_watting_lists;
      for (var i in files) {
        var filename = files[i].name;
        Object.keys(that.tasks).forEach(function (key) {
          if (filename == that.tasks[key].f_name) {
            files[i].status = that.tasks[key].status;

            files[i].task_id = key;
          }
        });
      }
      that.cad_pdf_watting_lists = files;
    },

    //得到需要的对应参数
    set_send_param() {
      var param = {};
      if (this.cad_pdf_xtys == 1) {
        param.line_color = "colors";
      } else {
        param.line_color = "bw";
      }

      if (this.cad_pdf_bgys == 1) {
        param.bg_color = "black";
      } else {
        param.bg_color = "white";
      }

      if (this.cad_pdf_zzfx == 1) {
        param.pagedct = 0;
      } else {
        param.pagedct = 1;
      }

      if (this.cad_pdf_wzsz == 1) {
        param.fontor = "no";
      } else {
        param.fontor = "yes";
      }

      if (this.cad_pdf_qxd == 1) {
        param.clarity = "normal";
      } else if (this.cad_pdf_qxd == 2) {
        param.clarity = "hd";
      } else {
        param.clarity = "ultrahd";
      }

      if (this.cad_pdf_zzfx == 1) {
        param.line_wt = "close";
        param.line_wt_num = this.cad_pdf_xksz_num;
      } else {
        param.line_wt = "open";
        param.line_wt_num = this.cad_pdf_xksz_num;
      }

      if (this.cad_pdf_sbtk == 1) {
        param.frame = "yes";
      } else {
        param.frame = "no";
      }

      if (this.cad_pdf_zcfs == 1) {
        param.type = "morefile";
      } else {
        param.type = "onefile";
      }

      return param;
    },

    cad_pdf_send_btn() {
      this.convert_status = "converting";
      this.task_weight_all = this.cad_pdf_watting_lists.length * 10;
      this.tasks = {}; // 先清空
      var arr = this.cad_pdf_watting_lists;
      for (var i in arr) {
        var item = arr[i];
        var formData = new FormData();
        var param = this.set_send_param();

        formData.append("file", item);
        formData.append("convert_type", "cad2pdf");
        formData.append("pagesize", this.value_zzdx);
        formData.append("pagedct", param.pagedct);
        formData.append("line_color", param.line_color);
        formData.append("bg_color", param.bg_color);
        formData.append("clarity", param.clarity);
        formData.append("fontor", param.fontor);
        formData.append("frame", param.frame);
        formData.append("type", param.type);
        formData.append("line_wt", param.line_wt);
        formData.append("line_wt_num", param.line_wt_num);

        cad_pdf_upload(formData).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            this.tasks[data.data.f_id] = {
              status: 0,
              f_name: data.data.filename,
            };
            if (!this.is_get_status) {
              this.get_status();
              this.reset_files_box();
            }
            this.is_get_status = true;
          }
        });
      }
    },

    // 轮询 等待转换结果
    get_status() {
      var that = this;
      that.timer = setInterval(function () {
        var task_ids = [];
        var tasks = that.tasks;
        for (var f_id in tasks) {
          if (tasks[f_id].status == 0 || tasks[f_id].status == 1) {
            task_ids.push(f_id);
          }
        }
        if (task_ids.length == 0) {
          clearInterval(that.timer);
          that.convert_status = "finished";
          that.reset_files_box();
          return;
        }
        that.convert_status = "converting";
        that.reset_files_box();
        var send = {
          convert_type: "cad2pdf",
          task_ids: JSON.stringify(task_ids),
        };
        cad_pdf_get_status(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            var datas = data.data;
            for (var i in datas) {
              if (datas[i].status == 2) {
                tasks[datas[i].f_id]["status"] = 2;
                if (task_ids.length == 1) {
                  clearInterval(that.timer);
                  that.convert_status = "finished";
                  that.reset_files_box();
                }
              } else if (datas[i].status == 1) {
                tasks[datas[i].f_id]["status"] = 1;
              } else if (datas[i].status == 3) {
                tasks[datas[i].f_id]["status"] = 3;
                if (task_ids.length == 1) {
                  clearInterval(that.timer);
                  that.convert_status = "finished";
                  that.reset_files_box();
                }
              } else if (datas[i].status == 4) {
                tasks[datas[i].f_id]["status"] = 4;
                if (task_ids.length == 1) {
                  clearInterval(that.timer);
                  that.convert_status = "finished";
                  that.reset_files_box();
                }
              }
            }
            that.tasks = tasks;
            that.convert_finished(tasks);
          } else {
            clearInterval(that.timer);
          }
        });
      }, 2000);
    },

    //重试
    retry_file_pdf(task_id) {
      var send = {
        convert_type: "cad2pdf",
        task_id: task_id,
      };
      cad_pdf_retry(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.tasks[task_id].status = 0;
          this.get_status();
        } else {
          this.$message.error("服务器繁忙");
        }
      });
    },

    download_file_pdf(task_id) {
      let a = document.createElement("a"); // 创建a标签
      let e = document.createEvent("MouseEvents"); // 创建鼠标事件对象
      e.initEvent("click", false, false); // 初始化事件对象
      a.href =
        Global.httpsUrl +
        "/cloud/online/download?convert_type=" +
        "cad2pdf" +
        "&task_id=" +
        task_id; // 设置下载地址
      a.download = ""; // 设置下载文件名
      a.dispatchEvent(e);
    },

    convert_finished(tasks) {
      this.set_progress(tasks);
      this.reset_files_box();
    },

    set_progress(tasks) {
      var tasks_weight_finished = 0;
      var tasks_len = Object.keys(tasks).length;
      tasks_weight_finished += 2 * tasks_len;
      Object.keys(tasks).forEach(function (key) {
        if (tasks[key].status == 1) {
          tasks_weight_finished += 2;
        } else if (tasks[key].status == 2 || tasks[key].status == 3) {
          tasks_weight_finished += 8;
        }
      });

      var step = 20;
      if (this.task_weight_all != 0) {
        step = (tasks_weight_finished / this.task_weight_all).toFixed(2) * 100;
      }

      this.finished_1 = parseInt(step);
      this.finished_2 = parseInt(step);
      // $("#cur_progress").css("width", parseInt(step) + "%");
      // $("#cur_progress_num").html(parseInt(step));
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.con-right {
  width: calc(100% - 285px);
  min-width: 78%;
  height: 100%;
  overflow: auto;
  margin-top: 24px;
  margin-left: 30px;
  min-width: 1000px;
}
// .cp-type-box {
//   display: none;
// }
.cp-set input {
  margin: 0;
}

.ci-set {
  display: flex;
  align-items: center;
}

select {
  outline: none;
}

.convert-set {
  margin-top: 40px;
  margin-left: 40px;
  padding-bottom: 30px;
}

.set-item-page-size {
  width: 160px;
  height: 30px;
  margin-top: -5px;
  background-color: #f2f3f5;
  border: 1px solid #979797;
  font-size: 14px;
  text-indent: 5px;
}
.cp-fontor input:not(:first-child) {
  margin-left: 35px;
}
.cp-pagedct input:not(:first-child) {
  margin-left: 20px;
}
.cp-linewt input:not(:first-child) {
  margin-left: 20px;
}

.cp-type-box {
  display: none;
}

.cp-set input {
  margin: 0;
}

.ci-set {
  display: flex;
  align-items: center;
}

select {
  outline: none;
}

.convert-set {
  margin-top: 40px;
  margin-left: 40px;
  padding-bottom: 30px;
}

.set-item-page-size {
  width: 160px;
  height: 30px;
  margin-top: -5px;
  background-color: #f2f3f5;
  border: 1px solid #979797;
  font-size: 14px;
  text-indent: 5px;
}
.cp-fontor input:not(:first-child) {
  margin-left: 35px;
}
.cp-pagedct input:not(:first-child) {
  margin-left: 20px;
}
.cp-linewt input:not(:first-child) {
  margin-left: 20px;
}

.cp-title {
  width: 130px;
}
.cp-line-color,
.ci-line-color {
  margin-left: 0;
}

.cp-frame,
.cp-type {
  margin-left: 0;
}

.cp-clarity,
.ci-clarity {
  margin-left: 0;
}

.fbfi-x img {
  width: 20px;
  height: 20px;
}
</style>