import request from '@/utils/request'
import qs from 'qs'
// CAD转PDF
export const cad_pdf_upload = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/online/upload',
    data: data
  })
}

export const cad_pdf_get_status = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/online/get_status',
    data: qs.stringify(data)
  })
}

export const cad_pdf_retry = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/online/retry',
    data: qs.stringify(data)
  })
}
  