<template>
  <!-- 引导界面 -->
  <div class="guide_warp" style="display: block" v-if="$store.state.guide_action">
    <div class="guide_box">
      <div class="guide_main" v-if="cur_count == 1">
        <img src="@/assets/images/common/guide1.png" />
        <div id="guide_left"></div>
        <div id="guide_right" @click="cur_count = 2"></div>
        <div class="guide_main_center">
          <div class="guide_main_text">{{one.text}}</div>
          <div class="guide_main_text1">
            {{one.text1}}
          </div>
          <div id="guide_main_btn" @click="cur_count = 2">{{one.btnText}}</div>
        </div>
      </div>
      <div class="guide_main" v-if="cur_count == 2">
        <img src="@/assets/images/common/guide2.png" />
        <div id="guide_left" @click="cur_count = 1"></div>
        <div id="guide_right" @click="cur_count = 3"></div>
        <div class="guide_main_center">
          <div class="guide_main_text">{{two.text}}</div>
          <div class="guide_main_text1">
            {{two.text1}}
          </div>
          <div id="guide_main_btn" @click="cur_count = 3">{{two.btnText}}</div>
        </div>
      </div>
      <div class="guide_main" v-if="cur_count == 3">
        <img src="@/assets/images/common/guide3.png" />
        <div id="guide_left" @click="cur_count = 2"></div>
        <div id="guide_right"></div>
        <div class="guide_main_center">
          <div class="guide_main_text">{{three.text}}</div>
          <div class="guide_main_text1">
            {{three.text1}}
          </div>
          <div id="guide_main_btn" @click="setLocationStore">{{three.btnText}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideViewer",
  data() {
    return {
      cur_count:1,
      one: {
        text: '打开网页就能用的「云端CAD」',text1: '无需安装软件，即可打开、测量、编辑CAD图纸', btnText: '下一步',
      },
      two: {
        text: '分享在线只读CAD，保护设计成果',text1: '接收方仅可查看、测量CAD，无法获取CAD原文件', btnText: '下一步'
      },
       three: {
        text: '轻松建群，协作办公',text1: '自由创建共享群，轻松管理，实时协作，共享图纸', btnText: '立即体验'
      },
    };
  },
  mounted() {

   var guide_action = localStorage.getItem('guide_action')
   if (guide_action == null || guide_action == 0) {
    this.$store.state.guide_action = true
    this.$store.state.mask = true
    localStorage.setItem('guide_action', 0)
   }
  },
  methods: {
    // 最后一步设置缓存已读
    setLocationStore() {
      this.$store.state.guide_action = false
      this.$store.state.mask = false
      localStorage.setItem('guide_action', 1)
    },
  },
};
</script>

<style lang="less" scoped>
.guide_warp {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 55;
  display: none;
  position: absolute;
  user-select: none;
}

.guide_box {
  width: 576px;
  height: 656px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.guide_main {
  position: relative;
}

#guide_left {
  top: 315px;
  left: 25px;
}

#guide_left,
#guide_right {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
}

#guide_right {
  top: 315px;
  right: 10px;
}

.guide_main_center {
  width: 476px;
  position: absolute;
  left: 50px;
  bottom: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.guide_main_text {
  font-family: "PingFang-SC-Bold";
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #000000;
  margin: auto;
  padding-bottom: 16px;
}

.guide_main_text1 {
  font-family: "PingFang-SC-Medium";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #666666;
  padding-bottom: 38px;
}

#guide_main_btn {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #458af1;
  border-radius: 20px;
  border: solid 1px #f2f3f5;
  font-family: "SourceHanSansCN-Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}
</style>