// 分享文件
import request from '@/utils/request'
import qs from 'qs'

// 获取分享地址
export const get_share_code = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/dwgapi/online_sharefile',
    data: qs.stringify(data)
  })
}

// 获取分享地址
export const get_share_code_tongbu = (data) => {
  return request({
    method: 'POST',
    async: false,
    url: '/cloud/dwgapi/online_sharefile',
    data: qs.stringify(data)
  })
}

// 获取分享地址
export const get_share_linkfile_code_tongbu = (data) => {
  return request({
    method: 'POST',
    async: false,
    url: '/cloud/dwgapi/online_sharefile_lf',
    data: qs.stringify(data)
  })
}

// 获取linkfile分享地址
export const get_share_linkfile_code = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/dwgapi/online_sharefile_lf',
    data: qs.stringify(data)
  })
}

// 批量发送图纸
export const online_sharefile_m = (data) => {
  return request({
    method: 'POST',
    async: false,
    url: "/cloud/dwgapi/online_sharefile_m",
    data: qs.stringify(data)
  })
}

// 批量发送图纸页面获取列表
export const get_mshare_files = (data) => {
  return request({
    method: 'POST',
    async: false,
    url: "/cloud/share/get_mshare_files",
    data: qs.stringify(data)
  })
}

// 生成的png下载
export const qcode_down = (url) => {
  return request({
    method: 'POST',
    responseType: 'blob',
    url: url,
  })
}

// 批量将分享文件保存到云盘 可下载的 存为 file
export const save_to_mycloud = (data) => {
  return request({
    method: 'POST',
    url: "/cloud/share/save_to_mycloud",
    data: qs.stringify(data)
  })
}

// 批量将分享文件保存到云盘 不可下载的 存为like_file
export const share_save_lf = (data) => {
  return request({
    method: 'POST',
    url: "/api/cloudlink/share_save_lf",
    data: qs.stringify(data)
  })
}

// 批量将分享文件保存到云盘 不可下载的 存为like_file
export const share_file_m_upd = (data) => {
  return request({
    method: 'POST',
    url: "/cloud/dwgapi/sharefile_m_upd",
    data: qs.stringify(data)
  })
}



