import Vue from 'vue'
import 'axios'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import './assets/css/reset.css'
import './assets/css/border.css'
import './styles/index.less'
import Contextmenu from "vue-contextmenujs"
import './assets/css/msg.css'
import Message from '../src/components/TMessage/TMessage';
import './baidutj';
import './utils/dialog';
import VueLazyload from "vue-lazyload";

//挂载到全局
Vue.prototype.$msg = Message;

Vue.use(Contextmenu);

Vue.use(VueLazyload,{
  preLoad: 1.3,
  loading: require('@/assets/images/dwg_menus_four/tk/gallery_loading.png'),
  attempt: 1
})

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
