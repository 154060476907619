<template>
  <div class="pay-box">
    <div class="pay-page1" v-if="cur_pay_page == '1'">
      <div class="pay-top">
        <div class="pt-l">
          <img :src="$store.state.userinfo.is_wx == 0 ? avatar : $store.state.userinfo.wx_avatar"/>
          <div id="pay_account">
            {{
              $store.state.userinfo.user_name != ""
                ? $store.state.userinfo.account +
                  "（" +
                  ($store.state.userinfo.is_wx == 0 ? $store.state.userinfo.user_name : $store.state.userinfo.wx_nickname) +
                  "）"
                : $store.state.userinfo.account
            }}
            正在购买服务
          </div>
        </div>
        <div id="pay_exit" @click="exit_pay_box('no')">
          <img src="@/assets/images/pay/exit.png" />
        </div>
      </div>
      <div class="pay-content">
        <div class="pc-l">
          <div class="pcl-top">
            <div class="pclt-img">
              <img src="@/assets/images/pay/vip_logo.png" />
            </div>
            <div class="pclt-word1">悟空云-在线CAD</div>
            <div class="pclt-word1">个人版</div>
            <div class="pclt-word2">解锁所有会员功能</div>
          </div>
          <div class="pcl-line"></div>
          <div class="pcl-content">
            <div>• 无需安装，在线使用</div>
            <div>• 自由查看，轻松编辑</div>
            <div>• 快速分享，在线只读</div>
            <div>• 轻松建群，协作办公</div>
          </div>
        </div>
        <div class="pc-r">
          <div class="vip-items">
            <div
              class="vip-item"
              :class="item.active ? 'vip-item-active' : ''"
              v-for="(item, i) in vip_items"
              :key="i"
              @click="chekout_item(i)"
            >
              <div class="vip-item-word"></div>
              <div class="vip-item-price">
                <span class="vip-price">{{ item.day1 }}</span
                ><span class="vip-day">{{ item.day2 }}</span>
              </div>
              <div class="vip-item-low">{{ item.low }}</div>
              <div class="vip-item-recommend" v-if="item.recommend">推荐</div>
              <div class="vip-item-gouxuan" v-if="item.active">
                <img src="@/assets/images/pay/gouxuan.png" />
              </div>
            </div>
          </div>
          <div class="vip-aggrement">
            <div
              class="vip-aggrement-input"
              data-val="1"
              @click="is_agree_btn()"
            >
              <img src="@/assets/images/pay/gouxuan2.png" v-show="is_agree" />
            </div>
            <div class="vip-aggrement-word">
              同意并接受<a
                href="https://www.aec188.com/agreement.php"
                target="_blank"
                style="color: #4383f0; text-decoration: none"
                >《会员服务协议》</a
              >
            </div>
          </div>
          <div class="vip-line"></div>
          <div class="pay-real-num">
            实际支付：<span class="pay-real-num-unit">￥</span
            ><span id="pay_real_num">{{ cur_price }}</span
            >元
          </div>
          <div
            class="to_pay"
            :class="is_agree ? '' : 'to_pay_no'"
            @click="go_to_pay()"
          >
            立即支付
          </div>
        </div>
      </div>
    </div>
    <div class="pay-page2" v-if="cur_pay_page == '2'">
      <div class="pp2-top">
        <div class="pp2-return" @click="return_page1()">
          <img src="@/assets/images/pay/return.png" />
          <div>返回</div>
        </div>
        <div id="pp2_exit" @click="exit_pay_box('clear')">
          <img src="@/assets/images/pay/exit.png" />
        </div>
      </div>
      <div class="pp2-content">
        <div class="pp2c-word">
          <div class="pp2c-word1">
            支付金额：<span id="pay_money">{{ pay_money }}</span
            >元
          </div>
          <div class="pp2c-word2">
            购买物品：<span id="pay_name">{{ pay_name }}</span
            ><span class="split"></span>交易订单：<span id="ordersn">{{
              pay_ordersn
            }}</span>
          </div>
        </div>
        <div class="pp2c-pay" v-if="cur_pay_action == 'waitting'">
          <div class="pay-qrcode">
            <div id="pay_qrcode" v-loading="is_loading">
              <!-- 二维码 -->
              <div id="qrcode" ref="qrcode" v-if="pay_code != ''"></div>
            </div>
          </div>
          <div class="pay-type">
            <div
              id="alipay_btn"
              :class="cur_pay_type == 'alipay' ? 'alipay_btn_active' : ''"
              data-canclick="no"
              @click="getPayCode_btn('alipay')"
            >
              <img src="@/assets/images/pay/zhifubao.png" />
              <span>支付宝</span>
            </div>
            <div
              id="wxpay_btn"
              :class="cur_pay_type == 'wxpay' ? 'alipay_btn_active' : ''"
              data-canclick="yes"
              @click="getPayCode_btn('wxpay')"
            >
              <img src="@/assets/images/pay/weixin.png" />
              <span>微信</span>
            </div>
          </div>
        </div>
        <div class="pp2c-pay-success" v-if="cur_pay_action == 'success'">
          <div class="pay-success">
            <img src="@/assets/images/pay/pay_success.png" />
            <div>会员支付成功</div>
          </div>
          <div style="margin-top: 16px; text-align: center">
            <!-- 3秒后将刷新页面会员信息~ -->
            3秒后自动关闭付费页面~
          </div>
        </div>

        <div class="pp2c-pay-success" v-if="cur_pay_action == 'error'">
          <div class="pay-success">
            <img src="@/assets/images/pay/pay_error.png" />
            <div>会员支付失败</div>
          </div>
          <div style="margin-top: 16px; text-align: center">
            <!-- 3秒后将刷新页面会员信息~ -->
            重新支付或者联系客服
            <span style="color: #4383f0">400-600-2063</span>
          </div>
        </div>
      </div>
    </div>

    <div class="pay-page3" v-if="cur_pay_page == '3'">
      <div class="pp2-top">
        <div class="pp3-return" @click="return_page1()">
          <img src="@/assets/images/pay/return.png" />
          <div>返回</div>
        </div>
        <div id="pp3_exit" @click="exit_pay_box('clear')">
          <img src="@/assets/images/pay/exit.png" />
        </div>
      </div>
      <div class="pp3-main">
        <table border="1" cellspacing="0">
          <tr>
            <th></th>
            <th>
              <img src="@/assets/images/pay/avatar.png" /><span>普通用户</span>
            </th>
            <th>
              <img src="@/assets/images/pay/avatar.png" /><span>注册用户</span>
            </th>
            <th>
              <img src="@/assets/images/pay/vip_logo.png" /><span
                >52CAD用户</span
              >
            </th>
          </tr>
          <tr>
            <td>天正云转换（限免）</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>PDF转CAD</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>批量出图</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>识别图框打印</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>识别图框转PDF</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>批量转DXF</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>DXF转DWG</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>保存图纸</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>发送图纸</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>创建群</td>
            <td><img src="@/assets/images/pay/hook_x.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
            <td><img src="@/assets/images/pay/hook.png" /></td>
          </tr>
          <tr>
            <td>云盘扩容</td>
            <td>≤100MB</td>
            <td>≤100MB</td>
            <td>≤1GB</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { get_pay_code, get_pay_status, reflush_vip } from "@/api/pay";
import QRCode from "qrcodejs2";

export default {
  name: "PayView",
  props: {
    sendUserInfo: {
      type: Object
    }
  },
  data() {
    return {
      vip_items: [
        {
          price_id: "126",
          price: 168,
          day1: 12,
          day2: "个月",
          low: "￥0.46元/天",
          active: true,
          recommend: true,
        },
        {
          price_id: "160",
          price: 118,
          day1: 6,
          day2: "个月",
          low: "￥0.64元/天",
          active: false,
          recommend: false,
        },
      ],
      cur_price_id: "126",
      cur_price: 168,
      pay_ordersn: "--",
      pay_code: "--",
      pay_name: "--",
      pay_money: "--",
      timer: null,
      is_agree: true,
      cur_pay_page: "1",
      cur_pay_action: "waitting",
      cur_pay_type: "alipay",
      is_loading: true,
      avatar: require("@/assets/images/avatar.png"),
    };
  },
  created() {
    if (JSON.stringify(this.$store.state.userinfo) == "{}") {
      if (this.$props.sendUserInfo && JSON.stringify(this.$props.sendUserInfo) != "{}") {
        this.$store.state.userinfo = this.$props.sendUserInfo;
      }
    }
  },

  mounted() {},

  methods: {
    exit_pay_box(value) {
      if (value == 'clear') {
        clearInterval(this.timer)
      }
      this.$store.state.is_show_pay = false;
      this.$store.state.mask = false;
      this.cur_pay_page = '1'
      this.cur_pay_type = 'alipay'
      this.cur_pay_action = 'waitting'
    },

    chekout_item(i) {
      this.vip_items.forEach((item, index) => {
        item.active = false;
        if (i == index) {
          item.active = true;
          this.cur_price = item.price;
          this.cur_price_id = item.price_id;
        }
      });
    },

    is_agree_btn() {
      this.is_agree = !this.is_agree;
    },

    go_to_pay() {
      if (!this.is_agree) return;
      this.cur_pay_page = "2";
      this.getPayCode("alipay");
    },

    //返回page1
    return_page1() {
      clearInterval(this.timer)
      this.cur_pay_page = "1";
    },

    getPayCode(pay_type) {
      this.is_loading = true;
      var data = {
        account: this.$store.state.userinfo.account,
        price_id: this.cur_price_id,
        pay_type: pay_type,
      };
      get_pay_code(data).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录')
          return;
        } else if (data.code == 1) {
          this.pay_money = data.money;
          this.pay_ordersn = data.ordersn;
          this.pay_code = data.pay_code;
          this.pay_name = data.pay_name;
          this.creatQrCode(this.pay_code);
          this.getPayStatus(this.pay_ordersn);
        } else {
          console.log("服务器繁忙");
          this.$msg.whiteerr('服务器繁忙，请稍后再试~')
        }
      });
    },
    creatQrCode(url) {
      setTimeout(() => {
        this.$refs.qrcode.innerHTML = ''
        new QRCode(this.$refs.qrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: url,
          width: 185,
          height: 185,
          colorDark: "#333", //二维码颜色
          colorLight: "#fff", //二维码背景颜色
          correctLevel: QRCode.CorrectLevel.H, //容错率,L/M/H
        });
        this.is_loading = false;
      }, 200);
    },

    getPayCode_btn(type) {
      this.cur_pay_type = type;
      this.getPayCode(type);
    },

    // 轮询
    getPayStatus(ordersn) {
      var data = {
        ordersn: ordersn,
      };
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        get_pay_status(data).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            clearInterval(this.timer)
            return;
          } else if (data.code == 1) {
            //支付成功
            clearInterval(this.timer)
            this.cur_pay_action = 'success'
            this.reflushVip()
            setTimeout(() => {
              this.exit_pay_box('clear')
            }, 3000);
          } else if (data.code == 2) {
            //未支付
          } else {
            clearInterval(this.timer)
            this.cur_pay_action = 'error'
            //支付失败
          }
        });
      }, 2000);
    },

    reflushVip () {
      reflush_vip().then(res=>{
        var data = JSON.parse(res.data)
        data.userinfo.is_vip = 1
        this.$store.state.userinfo = data.userinfo
        localStorage.setItem("USER_INFO", JSON.stringify(data.userinfo));
        if (this.$route.path == '/dwg') {
          this.$EventBus.$emit("reflushUserinfo");
        } else if (this.$router.path == '/usercenter') {
          this.$EventBus.$emit("reflushUserinfoCenter")
        } else if (this.$router.path == '/bip') {
          this.GetLocalStorageUserinfo()
        } else {
          this.$EventBus.$emit("reflushUserinfoIndex");
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.pay-box {
  width: 950px;
  height: 594px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  top: calc(50% - 297px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 998;
  box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%);
}

.pay-content {
  display: flex;
  flex-direction: row;
}

.pay-top {
  float: left;
  width: 100%;
  height: 60px;
  background-image: url("@/assets/images/pay/top_bg.png");
  position: relative;
}

.pt-l {
  float: left;
  position: relative;
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 60px;
}

.pt-l img {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

#pay_account {
  float: left;
  font-size: 14px;
  color: #fff;
  padding-left: 10px;
}

#pay_exit {
  float: right;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-top: 18px;
  cursor: pointer;
}

#pay_exit img {
  width: 100%;
  height: 100%;
}

.pay-content {
  width: 100%;
}

.pc-l {
  width: 280px;
  height: 534px;
  border-right: 1px solid #e7e7e7;
}

.pclt-img {
  width: 100%;
  height: 50px;
  text-align: center;
  margin-top: 30px;
}

.pclt-img img {
  width: 50px;
}

.pclt-word1 {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.pclt-word2 {
  width: 218px;
  height: 30px;
  line-height: 30px;
  margin-left: 30px;
  margin-top: 15px;
  text-align: center;
  background-color: #f0f4fc;
  border-radius: 4px;
  color: #666;
  font-size: 14px;
}

.pcl-line {
  width: 218px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 30px;
  margin-top: 30px;
}

.pcl-content {
  width: 100%;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-top: 30px;
  /*margin-left: 30px;*/
  text-align: center;
}

.pcl-content div {
  height: 20px;
  line-height: 14px;
  margin-top: 14px;
}

#look_vip_privilege {
  width: 94px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #c6c6c6;
  cursor: pointer;
  color: #333;
  margin-top: 76px;
  margin-left: 92px;
}

.pc-r {
  margin-left: 30px;
}

.pcr-word1 {
  font-size: 14px;
  color: #333;
  margin-top: 30px;
}

.apply-software {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.apply-software-item {
  display: flex;
  flex-direction: row;
}

.apply-software-item:not(:first-child) {
  margin-left: 26px;
}

.apply-software-item img {
  width: 24px;
  height: 24px;
}

.apply-software-item div {
  font-size: 14px;
  color: #333;
  margin-left: 6px;
  margin-top: 3px;
}

.vip-items {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.vip-item {
  width: 190px;
  height: 130px;
  background-color: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.vip-item-active {
  background-image: linear-gradient(120deg, #464b60 0%, #343649 100%);
}

.vip-item-active .vip-item-price {
  color: #c1a174;
}

.vip-item-active .vip-item-low {
  color: #c1a174;
}

.vip-item-active .vip-price {
  color: #c1a174;
}

.vip-item:not(:first-child) {
  margin-left: 20px;
}

.vip-item-word {
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #333;
  font-size: 16px;
  margin-top: 16px;
}

.vip-item-price {
  width: 100%;
  text-align: center;
  transform: translateX(-25px);
  font-size: 18px;
  color: #333;
  line-height: 28px;
  position: relative;
}

.vip-price {
  font-size: 36px;
  color: #333;
}

.vip-item-low {
  text-align: center;
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin-top: 13px;
}

.vip-item-recommend {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 46px;
  height: 27px;
  background-image: linear-gradient(35deg, #ff5961 0%, #f64a4f 100%);
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  line-height: 27px;
  font-size: 12px;
  color: #fff;
}

.vip-item-gouxuan {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: #4d5472;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 4px;
  text-align: center;
}

.vip-item-gouxuan img {
  width: 18px;
  height: 16px;
  margin-top: 6px;
}

.vip-coupon {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  cursor: pointer;
  width: 110px;
}

.vip-coupon-input {
  width: 20px;
  height: 20px;
  border: 1px solid #c6c6c6;
}

.vip-coupon-input img {
  display: none;
}

.vip-coupon-word {
  margin-left: 10px;
  font-size: 14px;
  color: #c6c6c6;
}

.vip-aggrement {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  cursor: pointer;
  width: 225px;
}

.vip-aggrement-input {
  width: 20px;
  height: 20px;
  border: 1px solid #c6c6c6;
}

/* .vip-aggrement-input img {} */

.vip-aggrement-word {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.vip-line {
  width: 170px;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 10px;
}

.pay-real-num {
  font-size: 14px;
  color: #333;
  margin-top: 30px;
}

.pay-real-num-unit {
  font-size: 16px;
  color: #c1a174;
}

#pay_real_num {
  font-size: 40px;
  color: #c1a174;
}

.to_pay {
  width: 147px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  background-image: linear-gradient(120deg, #464b60 0%, #343649 100%),
    linear-gradient(#d8d8d8, #d8d8d8);
  border-radius: 4px;
  font-size: 16px;
  color: #e2d4c1;
  margin-top: 30px;
  cursor: pointer;
}

.to_pay_no {
  background: #999;
  color: #fff;
  cursor: not-allowed;
}

.pp2-top {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
}

.pp2-return,
.pp3-return {
  float: left;
  margin-left: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.pp2-return div,
.pp3-return div {
  margin-left: 10px;
  font-size: 15px;
  color: #333;
}

#pp2_exit,
#pp3_exit {
  float: right;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

#pp2_exit img,
#pp3_exit img {
  width: 100%;
  height: 100%;
}

.pp2-content {
  width: 100%;
}

.pp2c-word {
  width: 100%;
  height: 140px;
  background-color: #f0f4fc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.pp2c-word1 {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

#pay_money {
  font-size: 20px;
  color: #c1a174;
}

.pp2c-word2 {
  width: 100%;
  text-align: center;
  margin-top: 18px;
}

.pp2c-word2 .split {
  margin-left: 30px;
}

#pay_qrcode {
  width: 185px;
  height: 185px;
  margin-top: 50px;
  overflow: hidden;
}

#pay_qrcode img {
  width: 185px;
  height: 185px;
}

.pay-type {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
}

.pay-type div {
  width: 120px;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
  border: 1px solid #979797;
  color: #333;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.pay-type div img {
  width: 24px;
  height: 24px;
  margin-top: 11px;
}

.pay-type div span {
  margin-left: 6px;
}

#alipay_btn {
  margin-left: 307px;
}

#alipay_btn img {
  margin-left: 21px;
}

.alipay_btn_active {
  border: 1px solid #c1a174 !important;
  color: #c1a174 !important;
}

#wxpay_btn {
  margin-left: 96px;
}

#wxpay_btn img {
  margin-left: 29px;
}

.pay-page1 {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.pay-success {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}

.pay-success img {
  width: 30px;
  height: 30px;
  margin-left: 396px;
}

.pay-success div {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-left: 6px;
}

.pay-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-page3 {
  height: 100%;
}

.pp3-main {
  padding-top: 33px;
  height: calc(100% - 115px);
  overflow: auto;
  margin: 0 10px;
  /* padding-bottom: 30px; */
  /* scrollbar-color: transparent transparent;
        scrollbar-track-color: transparent;
        -ms-scrollbar-track-color: transparent; */
}

.pp3-main-top {
  padding-top: 33px;
  margin: 0 15px 0 10px;
}

.pp3-main table,
.pp3-main-top table {
  width: 92%;
  margin-left: 4%;
  border-collapse: collapse;
  border: 1px solid #dcdcdc;
}

.pp3-main tr,
.pp3-main th,
.pp3-main td,
.pp3-main-top tr,
.pp3-main-top th,
.pp3-main-top td {
  text-align: center;
  border: 1px solid #dcdcdc;
}

.pp3-main th,
.pp3-main-top th {
  font-size: 15px;
  letter-spacing: 0px;
  color: #333333;
  height: 70px;
  font-weight: 500;
}

.pp3-main-top th {
  width: 25%;
}

.pp3-main th span,
.pp3-main-top th span {
  display: block;
  padding-top: 6px;
}

.pp3-main td {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  width: 25%;
  height: 34px;
}

.pp3-main th img,
.pp3-main-top th img {
  width: 24px;
  height: 24px;
}

.vip-day {
  display: inline-block;
  position: absolute;
  // bottom: -4px;
}
</style>