<template>
  <el-dialog
    title="覆盖文件"
    :visible.sync="$store.state.is_show_cover_files_box"
    width="450px"
    :modal="true"
    :append-to-body="true"
    @close="handleClose"
    class="share_dwg_dialog"
  >
    <div
      class="cover_files_box"
      id="cover_files_box_copy"
      style="display: block"
    >
      <div class="cover_body">
        <div class="cover_body_text" id="copy_body_text">
          <div style="display: inline-block">当前位置已存在『</div>
          <span>{{ $store.state.copy_cur_item_data.name }}</span>
          <div style="display: inline-block">』，请确认是否覆盖？</div>
        </div>
      </div>

      <div class="cover_foot">
        <div
          class="cover_foot_left"
          id="copy_save_files"
          @click="save_two_file_btn"
        >
          <div>保留两个文件</div>
        </div>
        <div class="cover_foot_right">
          <div id="copy_btn_quxiao" @click="handleClose">取消</div>
          <div id="copy_btn_submit" @click="is_cover_file_btn">覆盖</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { copyfile, cloudlink_copy } from "@/api/get_cloud";
import utils from "@/utils/utils";

export default {
  name: "IsSaveTwoView",
  data() {
    return {
      usertoken: "",
    };
  },

  mounted() {
    var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
    if (USER_TOKEN != null) {
      this.usertoken = USER_TOKEN;
    }
  },
  methods: {
    handleClose() {
      this.$store.state.is_show_cover_files_box = false;
    },

    // 保存2个文件
    save_two_file_btn() {
      this.save_two_file(this.$store.state.copy_cur_item_data);
    },
    // 覆盖文件
    is_cover_file_btn() {
      this.copy_cloudlink_file_dialog(this.$store.state.copy_cur_item_data);
    },

    // 保留2个文件 需要先改名
    save_two_file(cur_copy_data) {
      var send;
      if (cur_copy_data.type == "file") {
        send = {
          usertoken: this.usertoken,
          from_dir: cur_copy_data.parent_dir,
          filename: cur_copy_data.name,
          to_dir: cur_copy_data.to_dir,
          force: false,
          newname: "",
        };
        var fileName = cur_copy_data.name
        send.newname = this.check_filename(fileName)
        copyfile(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            this.$store.state.is_show_cover_files_box = false;
            var send = { p: this.$store.state.cur_parent_dir };
            this.$parent.get_files(send);
          } else if (data.code == 2) {
            //同名 显示是否覆盖框
            this.$store.state.is_show_cover_files_box = true;
          } else if (data.code == 3) {
            this.$msg.whiteerr("所复制的文件不存在或已被删除");
          }
        });
      } else {
        send = {
          usertoken: this.usertoken,
          link_id: cur_copy_data.link_id,
          force: false,
          dir: cur_copy_data.dir,
          newname: "",
        };
        var fileName = cur_copy_data.name
        send.newname = this.check_filename(fileName)

        cloudlink_copy(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            this.$store.state.is_show_cover_files_box = false;
            var send = { p: this.$store.state.cur_parent_dir };
            this.$parent.get_files(send);
          } else if (data.code == 22) {
            //同名 显示是否覆盖框
            this.$store.state.is_show_cover_files_box = true;
          } else if (data.code == 3 || data.code == 4) {
            this.$msg.whiteerr('所复制的文件不存在或已被删除');
          }
        });
      }
    },

    // 多次重名
    check_filename(fileName) {
      // file_lists 当前文件列表
      var idX = 1;
      var is = true;
      var newFileName = "";
      var fistName = utils.getFileName(fileName)
      var lastName = utils.getExtensiona(fileName)
      while (is) {
        newFileName = fistName + "(" + idX + ")" + lastName;
        is = this.is_exist_name(newFileName);
        idX++;
        if (idX == 999) {
          is = false;
        }
      }
      return newFileName;
    },

    is_exist_name(fileName) {
        var is_exist_name = false;
        this.$store.state.tableData_my_drawings.forEach((item) => {
          if (fileName == item.name) {
            is_exist_name = true;
          }
        });
        return is_exist_name;
    },

    getFileName(name) {
      return name.substring(0, name.lastIndexOf("."));
    },

    // 强制覆盖文件 , 默认强制
    copy_cloudlink_file_dialog(cur_copy_data) {
      var send;
      if (cur_copy_data.type == "file") {
        send = {
          usertoken: this.usertoken,
          from_dir: cur_copy_data.parent_dir,
          filename: cur_copy_data.name,
          to_dir: cur_copy_data.to_dir,
          force: true,
        };

        copyfile(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            this.$store.state.is_show_cover_files_box = false;
            var send = { p: this.$store.state.cur_parent_dir };
            this.$parent.get_files(send);
          } else if (data.code == 2) {
            //同名 显示是否覆盖框
            this.$store.state.is_show_cover_files_box = true;
            // this.$msg.whiteerr('已存在同名目录或文件');
          } else if (data.code == 3) {
            this.$msg.whiteerr('所复制的文件不存在或已被删除');
          }
        });
      } else {
        send = {
          usertoken: this.usertoken,
          link_id: cur_copy_data.link_id,
          force: true,
          dir: cur_copy_data.dir,
        };

        cloudlink_copy(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            this.$store.state.is_show_cover_files_box = false;
            var send = { p: this.$store.state.cur_parent_dir };
            this.$parent.get_files(send);
          } else if (data.code == 22) {
            //同名 显示是否覆盖框
            this.$store.state.is_show_cover_files_box = true;
            // this.$msg.whiteerr('已存在同名目录或文件');
          } else if (data.code == 3 || data.code == 4) {
            this.$msg.whiteerr('所复制的文件不存在或已被删除');
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cover_files_box {
  background-color: #fff;
  border-radius: 5px;
}

.cover_foot {
  padding: 36px 0px 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cover_foot_left {
  color: #2e68f8;
  cursor: pointer;
}

.cover_foot_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.cover_foot_right div {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}

#copy_btn_quxiao,
#cover_dir_btn_quxiao,
#memory_full_btn_quxiao {
  color: #999;
  background-color: #fff;
  border-color: #999;
  cursor: pointer;
}

#copy_btn_quxiao:hover,
#cover_dir_btn_quxiao:hover,
#memory_full_btn_quxiao:hover {
  border: 1px solid #4383f0;
  color: #4383f0;
}

#copy_btn_submit,
#cover_dir_btn_submit,
#memory_full_btn_submit {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  margin-left: 10px;
  cursor: pointer;
}

.cover_heder {
  height: 50px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.cover_heder::after {
  content: "";
  width: calc(100% - 40px);
  position: absolute;
  left: 20;
  bottom: 0;
  border-bottom: 1px solid #d6d6d6;
}

.cover_hader_img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.cover_hader_img img {
  width: 30px;
  height: 30px;
}

.cover_body {
  overflow: hidden;
}

.cover_header_text {
  font-size: 20px;
  color: #333;
}

.cover_body_text {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /*white-space: initial;*/
}

#copy_btn_quxiao:hover {
  color: #4383f0;
  border: solid 1px #4383f0;
}

#cover_dir_box {
  display: none;
}

.search_dir_path {
  padding: 0 10px;
  visibility: hidden;
}

#memory_full_box {
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.memory_full_line {
  width: 410px;
  height: 4px;
  background-color: #f04343;
  border-radius: 2px;
  margin: 10px 0;
}

.memory_full_text {
  width: 295px;
  height: 20px;
  font-family: SourceHanSansSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
}

.memory_full_body {
  padding: 20px;
}

#memory_full_foot {
  padding: 10px 20px 0 20px;
}

#memory_full_foot .cover_foot_right div {
  width: 100px;
  height: 32px;
  line-height: 32px;
  padding: 0;
}
</style>