<template>
  <!-- 群成员二维码 -->
  <el-dialog
    title="群成员"
    :visible.sync="$store.state.is_show_jion_team_qcode"
    width="456px"
    :modal="true"
    :append-to-body="true"
    @close="handleJionTeamClose"
    class="my_team_diglog my_jion_team_diglog"
  >
    <!-- 二维码链接 -->
    <div class="team-add-qrcode">
      <div style="position: relative">
        <div class="team-add-qrcode-user-mian" ref="dwon_div">
          <div class="team-add-qrcode-user">
            <img src="@/assets/images/team/avatar.png" />
            <span>{{ tname }}</span>
          </div>
          <div
            class="team-add-qrcode-qr"
            id="team-add-qrcode-qr"
            ref="jionteamqrcode"
            v-loading="is_loading"
          ></div>
          <div class="team-add-qrcode-user-text">请扫描二维码加入团队</div>
          <div class="team-add-qrcode-btn" @click="downLoadImage">
            下载二维码
          </div>
          <a href="" download="导出数据服务API图片.png" id="download"></a>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { create_team_code } from "@/api/get_cloud";

import html2Canvas from "html2canvas";

import QRCode from "qrcodejs2";

export default {
  name: "JionTeamView",
  data() {
    return {
      tname: "",
      autofocus: true,
      userinfo: "",
      is_loading: false,
      downurl: "",
    };
  },

  mounted() {
    var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userinfo != null) {
      this.userinfo = userinfo;
    }
  },

  methods: {
    handleJionTeamClose() {},

    get_qocde_jion_team(data) {
      this.tname = data.tname;
      this.show_jion_team_url_btn();
    },

    show_jion_team_url_btn() {
      this.is_loading = true;
      var send = {
        type: "forever",
        t_id: this.$store.state.get_team_data.t_id,
      };
      create_team_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = "https://www.wkcad.com/teamjion?t_code=" + data.t_code;
          this.downurl = url;
          this.creatQrCode(url);
          this.is_loading = false;
        } else if (data.code == 99) {
          this.$parent.login_expired_team();
        } else {
          this.$msg.whiteerr('服务器繁忙')
        }
      });
    },

    creatQrCode(url) {
      setTimeout(() => {
        this.$refs.jionteamqrcode.innerHTML = "";
        new QRCode(this.$refs.jionteamqrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: url,
          width: 160,
          height: 160,
          colorDark: "#333", //二维码颜色
          colorLight: "#fff", //二维码背景颜色
          correctLevel: QRCode.CorrectLevel.H, //容错率,L/M/H
        });
        this.is_loading = false;
      }, 200);
    },

    // 下载二维码
    downLoadImage() {
      const ref = this.$refs.dwon_div; // 截图区域
      html2Canvas(ref, {
        backgroundColor: "#e8f4ff",
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
      }).then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");
        const creatDom = document.createElement("a");
        document.body.appendChild(creatDom);
        creatDom.href = dataURL;
        creatDom.download = this.tname + "的群邀请码";
        creatDom.click();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.team-add-qrcode .tsu-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-add-qrcode .tsu-line1 {
  width: 416px;
  margin: auto;
}

#exit_team_qrcode {
  padding-right: 10px;
  width: 30px;
  height: 30px;
}

#exit_team_qrcode img {
  width: 100%;
  height: 100%;
}

.team-add-qrcode-user-mian {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 20px;
}

.team-add-qrcode-btn {
  width: 160px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%);
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}

.team-add-qrcode-user {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333333;
}

.team-add-qrcode-user img {
  width: 30px;
  height: 30px;
}

.team-add-qrcode-user span {
  padding-left: 10px;
}

.team-add-qrcode-user-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666666;
  padding-bottom: 20px;
}

.team-add-qrcode-qr {
  padding-top: 20px;
  padding-bottom: 10px;
}

.team-add-qrcode-qr {
  width: 160px;
  height: 160px;
}

.team-add-qrcode-qr-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666666;
}

#download {
  opacity: 0;
}
</style>