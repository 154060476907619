// 支付
import request from '@/utils/request'
import qs from 'qs'

export const get_pay_code = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/pay/get_pay_code',
    data: qs.stringify(data)
  })
}

//获取支付扫码状态
export const get_pay_status = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/pay/get_pay_status',
    data: qs.stringify(data)
  })
}


//支付成功后强制刷新会员状态
export const reflush_vip = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/login/reflush_vip',
    data: qs.stringify(data)
  })
}
