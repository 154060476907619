/* 拖拽盒子  */
import Vue from 'vue';

Vue.directive('dialogDrag', {
  // 自定义指令 实现可拖动
  inserted(el, bindings) {
    var is_in_drag = 0;
    var re_x,
        re_y;
    
    // 执行异步函数
    var elParent = el.parentNode; // 父节点
    // 画布 canvas
    var container_dwg = document.querySelector('.container_dwg');
    var canvas = container_dwg.querySelector('canvas');

    var bindings = bindings.value;

    el.addEventListener('mousedown', function(e) {
      is_in_drag = 1;
      var left = elParent.style.left || bindings.x+'px';// 给初始 x 值，dom 初次渲染获取不到元素位置
      var top = elParent.style.top || bindings.y+'px';  // 给初始 y 值
      left = left.substr(0, left.length-2);
      top = top.substr(0, top.length-2);
      re_x = parseInt(e.clientX - left);
      re_y = parseInt(e.clientY - top);
    })

    el.addEventListener('mouseup', function(e) {
      is_in_drag = 0;
    });

    document.addEventListener('mousemove', mouseMove);
    canvas.addEventListener('mousemove', mouseMove);

    function mouseMove(e) {
      if (is_in_drag == 1) {
        var moveX = e.clientX - re_x;
        var moveY = e.clientY - re_y;
        //控制移动坐标不能超出范围
        var maxX = document.documentElement.clientWidth; // 视口宽度
        var maxY = document.documentElement.clientHeight;// 视口高度
        var elW = elParent.offsetWidth;
        var elH = elParent.offsetHeight;

        if (moveX > maxX - elW) {
          moveX = maxX - elW;
        } else if (moveX < 0) {
          moveX = 0;
        }

        if (moveY > maxY - elH) {
          moveY = maxY - elH;
        } else if (moveY < 0) {
          moveY = 0;
        }

        elParent.style.left = moveX + 'px';
        elParent.style.top = moveY +'px';
      }
    }

    document.addEventListener('mouseup', function() {
      is_in_drag = 0;
    });
    canvas.addEventListener('mouseup', function() {
      is_in_drag = 0;
    });
  },
})