import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/index'
import Layout from "@/views/layout/index.vue";
import Recently_opened from "@/views/recently_opened/index";
import My_drawings from "@/views/my_drawings/index";
import My_collection from "@/views/my_collection/index";

import My_team from "@/views/my_team/index";
import CAD_PDF from "@/views/cad_pdf/index";
import CAD_IMG from "@/views/cad_img/index";
import CAD_VER from "@/views/cad_ver/index";
import CAD_TZ from "@/views/cad_tz/index";
import MShare from "@/views/mshare/index";

// import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '悟空云-在线CAD-2D/3D都能用' }
  },
  {
    path: '/cloud/online/dwg',
    name: 'olddwg',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/dwgdraw.vue')
  },
  {
    path: '/cloud/online/cdf',
    name: 'oldcdf',
    component: () => import(/* webpackChunkName: "about" */ '@/views/cdf/index')
  },
  {
    path: '/cloud1',
    component: Layout,
    children: [
      {
        path: '/recently_opened',
        name: 'recently_opened',
        component: Recently_opened,
        meta: { title: '最近打开' },
      },
      {
        path: '/my_drawings',
        name: 'my_drawings',
        component: My_drawings,
        meta: { title: 'CAD云盘' },
      },
      {
        path: '/my_collection',
        name: 'my_collection',
        component: My_collection,
        meta: { title: '我的云收藏' },
      },
      {
        path: '/my_team',
        name: 'my_team',
        component: My_team,
        meta: { title: '我的群' },
      },
      {
        path: '/cad_pdf',
        name: 'cad_pdf',
        component: CAD_PDF,
        meta: { title: 'CAD转PDF' },
      },
      {
        path: '/cad_img',
        name: 'cad_img',
        component: CAD_IMG,
        meta: { title: 'CAD转图片' },
      },
      {
        path: '/cad_ver',
        name: 'cad_ver',
        component: CAD_VER,
        meta: { title: 'CAD转版本' },
      },
      {
        path: '/cad_tz',
        name: 'cad_tz',
        component: CAD_TZ,
        meta: { title: '天正转换' },
      },
    ]
  },
  {
    path: '/dwgsee',
    name: 'dwgsee',
    meta: { title: '正在打开图纸...' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/index.vue')
  },
  {
    path: '/dwg',
    name: 'dwg',
    meta: { title: '正在打开图纸...' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/dwgdraw.vue')
  },
  {
    path: '/sync_demo',
    name: 'sync_demo',
    component: () => import(/* webpackChunkName: "about" */ '@/views/sync_demo/index.vue')
  },
  {
    path: '/sync_demo2',
    name: 'sync_demo2',
    component: () => import(/* webpackChunkName: "about" */ '@/views/sync_demo/index2.vue')
  },
  {
    path: '/dwg2',
    name: 'dwg2',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg2/dwgdraw.vue')
  },
  {
    path: '/dwg3',
    name: 'dwg3',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg3/dwgdraw.vue')
  },
  {
    path: '/cdf',
    name: 'cdf',
    meta: { title: '正在打开图纸...' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/cdf/index')
  },
  {
    path: '/pdf',
    name: 'pdf',
    meta: { title: '正在打开PDF...' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/pdf/index')
  },
  {
    path: '/privatecloudsdk',
    name: 'privatecloudsdk',
    meta: { title: '在线CAD-私有云SDK' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/private_cloud_sdk/index.vue')
  },
  {
    path: '/usercenter',
    name: 'usercenter',
    meta: { title: '个人中心' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/usercenter/index.vue')
  },

  {
    path: '/teamjion',
    name: 'teamjion',
    meta: { title: '加入群组' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/my_team_jion/index')
  },
  {
    path: '/mshare',
    name: 'mshare',
    component: MShare,
    meta: { title: '分享图纸' },
  },
  {
    path: '/not_support',
    name: 'not_support',
    meta: { title: '当前浏览器不支持' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/help_page/not_support')
  },
  {
    path: '/wap',
    name: 'wap',
    meta: { title: 'APP扫码打开图纸' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/wap/index')
  },
  {
    path: '/bip',
    name: 'bip',
    meta: { title: '悟空CAD工业云平台' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/bip/index')
  },
  {
    path: '/i/:share_id',
    name: 'i',
    component: () => import(/* webpackChunkName: "about" */ '@/views/i/index')
  },
  {
    path: '/l/:share_id',
    name: 'l',
    component: () => import(/* webpackChunkName: "about" */ '@/views/l/index')
  },
  {
    path: '/dwgdb',
    name: 'dwgdb',
    meta: { title: '图纸对比' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwgdb/index')
  },
  {
    path: '*',  // 重定向，防止错误路径
    redirect: '/recently_opened'
  }

]


const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/', //配置nginx访问结果
})


router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  window.document.title = to.meta.title == undefined ? '悟空云-在线CAD-2D/3D都能用' : to.meta.title
  next();
});


// // 路由拦截，判断是否需要登录
// router.beforeEach((to, from, next) => {
//   // if (to.meta.title) {
//   // //判断是否有标题
//   // document.title = to.meta.title;
//   // }
//   // console.log("title",document.title)
//   // 通过requiresAuth判断当前路由导航是否需要登录
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     let token = localStorage.getItem('USERTOKEN')
//     console.log("token",token)
//     // 若需要登录访问，检查是否为登录状态
//     if (!token) {
//       next({
//         path: '/',
//         query: { redirect: to.fullPath }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // 确保一定要调用 next()
//   }
// })


export default router
