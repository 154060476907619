<template>
  <div id="appA">
    <div id="main_header_bg">
      <div class="main_header_bg_flex">
        <div class="main_header_left">
          <a href="javascript:;">
            <img
              src="@/assets/images/common/logo.png"
              alt="悟空云CAD- 不下软件就能用的「在线CAD」"
            />
            <div class="main_header_title">{{ headerTitle }}</div>
          </a>
        </div>
        <div id="show_logo_shoucang">
          <div class="show_logo_shoucang_postion">
            <a href="https://www.wkcad.com">
              <div style="opacity: 0">悟空云CAD- 不下软件就能用的「在线CAD」</div>
              <img
                src="@/assets/images/main/shoucang.png"
                alt="悟空云CAD- 不下软件就能用的「在线CAD」"
              />
              <div>喜欢我，就拖动logo到标签进行收藏吧~</div>
            </a>
          </div>
        </div>
        <div class="main_header_right">
          <div class="main_header_bg_flex main_header_bg_flex1">
            <div class="syy_sdk" id="go_to_syy">
              <a href="/privatecloudsdk">企业专属的在线CAD-私有化部署方案 &gt;</a>
            </div>
            <div class="main_header_line"></div>
            <el-dropdown>
              <span
                class="el-dropdown-link feedback_show"
                id="feedback_show"
                style="color: #fff; font-size: 16px"
              >
                下载客户端
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="header_down_item" id="down_app_btn" @click="down_kehuduan_btn">
                  <img src="@/assets/images/common/down_win.png" alt="" />
                  <span>Windows版本</span>
                </div>
                <div
                  class="header_down_item"
                  id="show_app_sao_code_box"
                  @mouseover="is_show_down_app_qocde = true"
                  @mouseout="is_show_down_app_qocde = false"
                >
                  <img src="@/assets/images/common/down_app.png" alt="" />
                  <span>App扫码下载</span>
                  <div class="app_sao_code_box" v-show="is_show_down_app_qocde">
                    <div class="app_sao_code">
                      <img src="@/assets/images/common/basic.png" alt="" />
                      <div class="app_sao_code_text">手机扫码下载</div>
                      <div class="app_sao_code_text1">支持Android / IOS</div>
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="main_header_line"></div>
            <div
              class="go_to_login"
              @click="
                is_show_login = true;
                cur_show_box = 'login';
              "
              v-if="userinfo == null"
            >
              登录
            </div>
            <div
              class="go_to_reg"
              @click="
                is_show_login = true;
                cur_show_box = 'reg';
              "
              v-if="userinfo == null"
            >
              注册
            </div>

            <div class="go_to_mycloud" @click="go_cloud" v-if="userinfo != null">进入我的云图</div>
          </div>
        </div>
      </div>
    </div>
    <div class="top_bg_banner_box">
      <div id="header_imgUrl"></div>
      <div class="header_imgUrl_title_box">
        <div class="header_imgUrl_title1">悟空云-在线CAD</div>
        <div class="header_imgUrl_title2">无需下载软件，随时随地看图纸，快速发送图纸给好友</div>
        <div class="header_imgUrl_title3">打开图纸丨测量查看丨发送图纸</div>
        <div class="header_imgUrl_title_open_dwg" @click="go_cloud">打开图纸</div>
      </div>

      <div class="shili_box">
        <div class="shili_flex swiper-wrapper">
          <div
            class="shili_item swiper-slide"
            style="margin-left: 20px"
            id="go_to_cloud"
            @click="go_cloud"
          >
            <div class="go_to_cloud_bgimg"></div>
            <div class="go_to_cloud_text">打开我的图纸 ></div>
            <!-- <div class="shili_item_size">222.88KB</div> -->
          </div>

          <div class="shili_item swiper-slide" style="margin-left: 20px" @click="go_shilitu(1)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img01.png" />
            </div>
            <div class="shili_item_name">东明花苑平面布置图.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(2)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img02.png" />
            </div>
            <div class="shili_item_name">建筑结构图.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(3)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img03.png" />
            </div>
            <div class="shili_item_name">视频监控系统图.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(4)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img04.png" />
            </div>
            <div class="shili_item_name">设备用房给排水设计.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(5)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img05.png" />
            </div>
            <div class="shili_item_name">减速机-平面.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <!-- <div class="shili_item swiper-slide" style="margin-left: 30px;margin-right: 20px;">
            <div class="shili_item_img">
              <img src="https://open.appol.com/imgs/sample/img06.png">
            </div>
            <div class="shili_item_name">带联立式风机.dwg</div>
            <div class="shili_item_size">1.31MB</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="main_2">
      <div class="main_2_title1">跨平台使用，<span>随时随地查看图纸</span></div>
      <div class="main_2_title2">专注设计本身，高效工作新方式，未来已来</div>
      <div class="main_2_flex">
        <div class="main_2_flex_left">
          <div class="main_2_flex_left_1">
            无论是电脑端、移动设备，或是微信聊天中，您与您的工作伙伴，都可以打开浏览CAD图纸，测量、批注、沟通。
          </div>
          <div
            class="main_2_flex_left_2"
            :class="is_show_phone_or_pc ? 'main_2_flex_left_active' : ''"
            @click="checkout_shitu('phone')"
            @mouseover="checkout_shitu('phone')"
          >
            <img src="@/assets/images/main/shoujiduan.png" alt="" />
          </div>
          <div
            class="main_2_flex_left_2 main_2_flex_left_3"
            :class="!is_show_phone_or_pc ? 'main_2_flex_left_active' : ''"
            @click="checkout_shitu('pc')"
            @mouseover="checkout_shitu('pc')"
          >
            <img src="@/assets/images/main/diannaoduan.png" alt="" />
          </div>
        </div>
        <div class="main_2_flex_right">
          <div class="main_2_flex_right_title" v-if="is_show_phone_or_pc">手机端</div>
          <div class="main_2_flex_right_flex" v-if="is_show_phone_or_pc">
            <img src="@/assets/images/main/phone_url.png" alt="" />
            <img src="@/assets/images/main/phone_open_dwg.png" alt="" style="margin-left: 50px" />
          </div>

          <div class="main_2_flex_right_title" v-if="!is_show_phone_or_pc">电脑端</div>
          <div class="main_2_flex_right_flex" style="height: 580px" v-if="!is_show_phone_or_pc">
            <img src="@/assets/images/main/diannaoduan_bg.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="main_3" style="display: none">
      <div class="main_2_title1">权限设置，<span>安全可靠</span></div>
      <div class="main_2_title2">自由设置权限，真正的加密只读新格式</div>
      <div class="main_2_flex main_3_flex">
        <div class="main_3_flex_left">
          <img src="@/assets/images/main/main3_share.png" alt="" />
        </div>
        <div class="main_3_flex_right">
          <div class="main_3_flex_right_title">
            告别繁冗且有安全隐患的转PDF、加密等旧方式，在这里，有灵活的访问权限可供设置，真正的加密只读新格式等您体验。
          </div>
          <img src="@/assets/images/main/main3_share1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="main_4">
      <div class="main_2_title1" style="text-align: center; padding-top: 120px">
        企业解决方案<span>-在线CAD私有云SDK</span>
      </div>
      <div class="main_4_items">
        <div class="main_4_item">
          <img src="@/assets/images/main/main4_1.png" alt="" />
          <div class="main_4_item_text1">图纸云服务</div>
          <div class="main_4_item_text2">
            公有云图纸托管服务与私有云服务部署双重方案，满足企业更好的安全与使用需求。
          </div>
        </div>
        <div class="main_4_item">
          <img src="@/assets/images/main/main4_2.png" alt="" />
          <div class="main_4_item_text1">企业品牌服务</div>
          <div class="main_4_item_text2">
            企业水印，企业名称、logo等专属定制，助力企业文化与品牌的提升与渗透能力。
          </div>
        </div>
        <div class="main_4_item">
          <img src="@/assets/images/main/main4_3.png" alt="" />
          <div class="main_4_item_text1">正版授权</div>
          <div class="main_4_item_text2">
            摆脱国外软件版权束缚，国产自主研发，提供正版CAD软件授权。
          </div>
        </div>
      </div>
      <div class="go_to_call_we" @click="is_show_consult = true">
        <a>联系我们</a>
      </div>
    </div>

    <div class="main_5">
      <div class="main_5_flex">
        <div class="main_4_title main_5_title">研发团队</div>
        <div class="main_2_title2 main_5_title2">由迷你CAD研发团队倾力打造</div>
        <div class="main_5_title3">
          客户端软件CAD迷你看图、CAD迷你画图系列产品得到近<span>6000万</span>行业用户的信赖与使用。
        </div>
        <div class="main_5_title4">
          十年技术积累，十年磨一剑，为中国工程行业带来新一代web端CAD产品。
        </div>

        <div class="main_5_6000_flex">
          <img class="main_5_6000_left" src="@/assets/images/main/main_logo_right.png" />
          <div class="main_5_6000_center">6000万</div>
          <img class="main_5_6000_right" src="@/assets/images/main/main_logo_left.png" />
        </div>
        <div class="bg_6000">6000<span>万</span></div>
        <div class="footer_beian">
          版权所有&nbsp;&nbsp;开发者：上海晓材科技有限公司&nbsp;&nbsp; &nbsp;&nbsp;<a
            href="http://beian.miit.gov.cn/"
            target="_blank"
            title="备案号"
            rel="external nofollow noopener noreferrer"
            >沪ICP备15023605号-8</a
          >
        </div>
      </div>
    </div>

    <div class="cando_consult_box" v-if="is_show_consult" @keydown.esc="hideConsultBox">
      <div class="cando_consult">
        <div class="title">咨询专属顾问</div>
        <div class="close" @click="is_show_consult = false">
          <div class="close_left"></div>
          <div class="close_right"></div>
        </div>
        <ul class="consult_members">
          <li>
            <div class="name">
              <div class="line"></div>
              <span>揣小姐</span>
            </div>
            <div class="telephone">电话：13162771001</div>
            <div class="mailbox">邮箱：chuaiyunning@52cad.com</div>
          </li>
        </ul>
      </div>
    </div>

    <Login
      :cur_show_box="cur_show_box"
      :is_show_login="is_show_login"
      v-if="is_show_login"
      @change_child_value="change_child_value"
    ></Login>
  </div>
</template>

<script>
import Login from "@/components/login";
import { login_check, usertoken_login } from "@/api/user";
import utils from "@/utils/utils";
import Common from "@/utils/common";

export default {
  name: "Home_page",
  components: { Login },
  data() {
    return {
      headerTitle: Common.headerTitle,
      is_show_login: false,
      cur_show_box: "login",
      userinfo: null,
      is_show_phone_or_pc: true,
      is_show_down_app_qocde: false,
      is_show_consult: false,
    };
  },
  beforeCreate() {
    // utils.isIE(this)
    // utils.getBrowser(this)
  },
  mounted() {
    var usertoken = encodeURIComponent(utils.getQueryVariable("usertoken"));
    if (usertoken == "") {
      this.loginCheck();
    } else {
      this.auto_usertoken_login(usertoken, "token");
    }

    //监听键盘按键事件
    let self = this;
    this.$nextTick(() => {
      document.addEventListener("keyup", function (e) {
        //此处填写你的业务逻辑即可
        if (e.keyCode == 27) {
          self.hideConsultBox();
        }
      });
    });
  },

  methods: {
    hideConsultBox() {
      this.is_show_consult = false;
    },

    loginCheck() {
      var that = this;
      login_check().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          that.userinfo = data.data;
          localStorage.setItem("USERTOKEN", JSON.stringify(data.data.user_token));
          localStorage.setItem("USER_INFO", JSON.stringify(data.data));
        } else {
          that.userinfo = null;
          // utils.del_localStorae()
          that.auto_login();
        }
      });
    },

    // 自动登录
    auto_login() {
      var USER_INFO_TIME = parseInt(localStorage.getItem("USER_INFO_TIME"));
      var cur_time = new Date().getTime();
      if (cur_time < 7 * 86400 * 1000 + USER_INFO_TIME || USER_INFO_TIME == null) {
        // 7天内登录过
        var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
        if (USER_TOKEN != null) {
          //token存在
          this.auto_usertoken_login(USER_TOKEN, "auto");
        } else {
          //token不存在
          utils.del_localStorae();
          this.userinfo = null;
        }
      } else {
        utils.del_localStorae();
        this.userinfo = null;
      }
    },

    auto_usertoken_login(user_token, from) {
      var that = this;
      var send = {
        user_token: user_token,
      };
      usertoken_login(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var __userinfor = data.user_info;
          that.userinfo = data.user_info;
          localStorage.setItem("USER_INFO_TIME", new Date().getTime());
          localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
          localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
          that.$store.state.userinfo = __userinfor;
          if (from == "token") {
            this.$router.push({
              name: "recently_opened",
            });
          }
        } else {
          // that.$message.error('登录信息已过期，请重新登陆')
          utils.del_localStorae();
          that.userinfo = null;
        }
      });
    },

    //下载客户端
    down_kehuduan_btn() {
      utils.down_kehuduan();
    },

    go_cloud() {
      var user_token = JSON.parse(localStorage.getItem("USERTOKEN"));
      if (user_token != null) {
        this.$router.push({
          name: "recently_opened",
        });
      } else {
        this.is_show_login = true;
      }
    },

    go_shilitu(index) {
      var share_id;
      switch (index) {
        case 1:
          share_id = "hRBQGQU4FCuP";
          break;
        case 2:
          share_id = "eW9h4FPgKRAy";
          break;
        case 3:
          share_id = "3mawGiFrGDgt";
          break;
        case 4:
          share_id = "cIx9QHx0BTdJ";
          break;
        case 5:
          share_id = "dioGav3ajynZ";
          break;
        default:
          break;
      }
      let pathInfo = this.$router.resolve({
        path: "/dwg",
        query: {
          share_id: share_id,
        },
      });
      window.open(pathInfo.href, "_blank");
    },

    change_child_value(data) {
      this.is_show_login = data;
    },

    checkout_shitu(data) {
      if (data == "phone") {
        this.is_show_phone_or_pc = true;
      } else {
        this.is_show_phone_or_pc = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/deep/ body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "STHeitiSC-Light",
    "Microsoft YaHei", "SimSun", sans-serif;
  height: auto !important;
}

::-webkit-scrollbar {
  /* 滚动条整体部分 */
  width: 5px;
  margin-right: 2px;
}

::-webkit-scrollbar-button {
  /* 滚动条两端的按钮 */
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  margin-bottom: 2px;
}

::-webkit-scrollbar-track {
  /* 外层轨道 */
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  /*内层轨道，滚动条中间部分 */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  /* 滑块 */
  width: 5px;
  border-radius: 5px;
  background: #cbcbcb;
}

::-webkit-scrollbar-corner {
  /* 边角 */
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  /* 鼠标移入滑块 */
  background: #cbcbcb;
}

#main_header_bg {
  background-color: #3568c0;
  top: 0;
  width: 100%;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 1;
  position: fixed;
  min-width: 1250px;
}

#header_imgUrl {
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/main/banner.png");
  background-position: center 100%;
  background-repeat: no-repeat;
}

.main_header_bg_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1300px;
  min-width: 600px;
}

.main_header_left {
  width: 35%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.main_header_left a {
  display: flex;
  align-items: center;
  cursor: default;
}

.main_header_right {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main_header_title {
  font-family: SourceHanSansSC-Bold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 10px;
}

.main_header_title span {
  font-family: SourceHanSansSC-Bold;
  font-size: 18px;
}

.main_header_bg_flex1 {
  justify-content: flex-end;
}

.go_to_login,
.go_to_reg,
.go_to_mycloud {
  width: 100px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #fff;
  text-align: center;
  line-height: 34px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}

.go_to_mycloud {
  width: 120px;
}

.go_to_mycloud:hover {
  opacity: 0.8;
}

.go_to_reg {
  border: none;
  margin-left: 10px;
}

.el-header {
  padding: 0;
  height: 80px !important;
}

.top_bg_banner_box {
  position: relative;
  width: 100%;
  height: 712px;
  background: #f0f4fc;
}

.header_imgUrl_title_box {
  width: 1300px;
  height: 281px;
  position: absolute;
  top: calc(8% + 80px);
  left: 50%;
  margin-left: -650px;
  overflow: hidden;
}

.header_imgUrl_title1 {
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.header_imgUrl_title2 {
  font-family: SourceHanSansSC-Medium;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 18px;
  margin-bottom: 40px;
}

.header_imgUrl_title3 {
  font-family: SourceHanSansSC-Regular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 28px;
}

.header_imgUrl_title_open_dwg {
  width: 220px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 4px;
  font-family: SourceHanSansSC-Medium;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #4383f0;
  text-align: center;
  line-height: 70px;
  user-select: none;
  cursor: pointer;
}

.shili_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0 30px 0;
  /* overflow: auto; */
}

.shili_item {
  padding: 16px;
  width: 184px;
  height: 172px;
  background: #fff;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #333333;
}

.shili_item:hover {
  box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%);
  color: #4383f0;
}

.shili_item_img img {
  width: 182px;
  height: 118px;
}

.shili_item_name {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  width: 100%;
  padding-top: 16px;
}

.shili_item_size {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("@/assets/images/main/shilitu_biaoji.png");
  width: 64px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: #4383f0;
  border-radius: 5px 0px 5px 0px;
}

.shili_head img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  cursor: pointer;
}

.shili_box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;
}

.main_2 {
  background-color: #f0f4fc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  height: 1220px;
}

#appA {
  width: 100%;
  height: 100%;
  min-width: 1280px;
}

.main_2_title1 {
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #313131;
}

.main_2_title1 span {
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #4383f0;
}

.main_2_title2 {
  font-family: SourceHanSansSC-Regular;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  margin-top: 20px;
}

.main_2_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1300px;
  height: 780px;
  background-color: #ffffff;
  box-shadow: 0px 8px 10px 0px rgba(154, 169, 188, 0.24);
  border-radius: 30px;
  border: solid 1px #e7e7e7;
  margin-top: 80px;
}

.main_2_flex_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 520px;
}

.main_2_flex_right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 780px;
  height: 780px;
  background-image: linear-gradient(136deg, #458aff 0%, #50acf9 100%);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.main_2_flex_right_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 780px;
  height: 780px;
}

.main_2_flex_right_title {
  width: 84px;
  height: 40px;
  font-family: SourceHanSansSC-Regular;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 40px;
  margin-top: 30px;
}

.main_2_flex_left_1,
.main_2_flex_left_2,
.main_2_flex_left_3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_2_flex_left_1 {
  width: 368px;
  height: 87px;
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
}

.main_2_flex_left_2 {
  width: 368px;
  height: 182px;
  background-color: #ffffff;
  margin-top: 60px;
  cursor: pointer;
}

.main_2_flex_left_active {
  box-shadow: 0px 2px 9px 0px rgba(91, 110, 133, 0.31);
}

.main_2_flex_left_3 {
  width: 368px;
  height: 182px;
  margin-top: 60px;
}

.main_3 {
  width: 1300px;
  height: 998px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}

.main_3_flex {
  width: 1300px;
  height: 568px;
  justify-content: flex-start;
}

.main_3_flex_right_title {
  width: 401px;
  height: 87px;
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_3_flex_left {
  width: 740px;
  height: 480px;
  background-image: linear-gradient(134deg, #4e8cf6 0%, #50acf9 100%);
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
}

.main_3_flex_right {
  margin-left: 120px;
  position: relative;
}

.main_4 {
  background-size: 100% 100%;
  width: 100%;
  height: 650px;
  background-image: url("@/assets/images/main/main4_bg.png");
  background-position: center 100%;
  background-repeat: no-repeat;
}

.main_4_title {
  width: 288px;
  height: 70px;
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 auto;
  padding-top: 84px;
}

.main_4_items {
  width: 100%;
  height: 203px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.main_4_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 452px;
}

.main_4_item img {
  margin: 7px;
}

.main_4_item_text1 {
  font-family: SourceHanSansSC-Medium;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(38, 42, 55, 1);
  margin-top: 10px;
  margin-bottom: 30px;
}

.main_4_item_text2 {
  width: 400px;
  height: 58px;
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(38, 42, 55, 1);
}

.go_to_call_we {
  width: 159px;
  height: 52px;
  background-color: #4383f0;
  border-radius: 4px;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #fff;
  line-height: 52px;
  text-align: center;
  margin: 60px auto;
  user-select: none;
  cursor: pointer;
}

.go_to_call_we a {
  color: #fff;
}

.main_5 {
  width: 100%;
  height: 840px;
  background-color: #f0f4fc;
  position: relative;
  z-index: 0;
}

.main_5_flex {
  width: 1300px;
  margin: 0 auto;
}

.main_5_title {
  color: #040404;
  margin: 0 auto;
  text-align: center;
}

.main_5_title2 {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}

.main_5_title3 {
  /* width: px; */
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
}

.main_5_title4 {
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
}

.main_5_title3 span {
  color: #4383f0;
}

.main_5_6000_flex {
  margin-top: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main_5_6000_center {
  width: 152px;
  height: 58px;
  font-family: Arial-BoldMT;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #313131;
  text-align: center;
  margin: 0 20px;
  font-weight: 700;
}

.bg_6000 {
  height: 336px;
  text-align: center;
  font-size: 300px;
  color: #e7eefc;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
  z-index: -1;
  font-weight: 700;
  width: 100%;
  user-select: none;
}

.bg_6000 span {
  font-size: 100px;
}

.footer_beian {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #7a7a7a;
  font-size: 12px;
}

.footer_beian a {
  color: #4383f0;
}

.main_new_year {
  position: fixed;
  left: 0;
  bottom: 0;
  background-image: url("@/assets/images/main/main_new_year.png");
  background-size: auto 100%;
  width: 100%;
  height: 120px;
  background-position: center 100%;
  background-repeat: no-repeat;
}

.main_new_year_exit {
  position: absolute;
  right: 5px;
  bottom: 65px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_new_year_box {
  position: relative;
  width: 100%;
  height: 120px;
  cursor: pointer;
}

.main_header_title,
.go_to_login,
.go_to_reg,
.header_imgUrl_title1,
.header_imgUrl_title2,
.header_imgUrl_title3,
.header_imgUrl_title_open_dwg,
.shili_item_name,
.main_2_title1,
.main_2_title2,
.main_2_flex_left_1,
.main_2_flex_right_title,
.main_2_title1 span,
.main_3_flex_right_title,
.main_4_title,
.main_4_item_text1,
.main_4_item_text2,
.main_5_title3,
.main_5_title4 {
  font-family: "SourceHanSansSC-Bold", "PingFang SC";
}

@media (min-width: 1024px) {
  body {
    font-size: 18px;
  }

  .shili_item {
    width: 134px;
    height: 112px;
  }

  .shili_item img {
    width: 122px;
    height: 68px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
  }
}

/*>=1024的设备*/

@media (min-width: 1100px) {
  body {
    font-size: 20px;
  }

  .shili_item {
    width: 154px;
    height: 144px;
  }

  .shili_item img {
    width: 154px;
    height: 108px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
  }
}

/*>=1100的设备*/
@media (min-width: 1280px) {
  body {
    font-size: 22px;
  }

  .shili_item {
    width: 154px;
    height: 144px;
  }

  .shili_item img {
    width: 154px;
    height: 108px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
  }
}

/*>=1280的设备*/

@media (min-width: 1366px) {
  body {
    font-size: 24px;
  }

  .shili_item {
    width: 164px;
    height: 154px;
  }

  .shili_item img {
    width: 152px;
    height: 108px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
  }
}

@media (min-width: 1440px) {
  body {
    font-size: 25px !important;
  }

  .shili_item {
    width: 174px;
    height: 154px;
  }

  .shili_item img {
    width: 172px;
    height: 108px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
}

@media (min-width: 1680px) {
  body {
    font-size: 28px;
  }

  .shili_item {
    width: 204px;
    height: 195px;
  }

  .shili_item img {
    width: 202px;
    height: 138px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
  }
}

@media (min-width: 1920px) {
  body {
    font-size: 33px;
  }

  .shili_item {
    width: 224px;
    height: 215px;
  }

  .shili_item img {
    width: 222px;
    height: 158px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
  }
}

#show_logo_shoucang {
  position: absolute;
  left: 12.5%;
  top: 0;
  display: none;
  width: 400px;
  height: 100px;
}

.show_logo_shoucang_postion {
  position: relative;
}

.show_logo_shoucang_postion img {
  position: absolute;
}

.show_logo_shoucang_postion div {
  position: absolute;
  top: 67px;
  left: 0;
  font-size: 14px;
  text-align: center;
  width: 330px;
  opacity: 1;
}

.main_header_bg_flex a {
  color: #8a4023;
}

#go_to_cloud {
  background-color: #3568c0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go_to_cloud_bgimg {
  width: 60px;
  height: 60px;
  background-image: url("@/assets/images/main/go_dwg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.go_to_cloud_text {
  font-family: "SourceHanSansSC-Bold", "PingFang SC";
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 24px;
}

#maskLayer {
  background-color: #000 !important;
  opacity: 0.5 !important;
}

.main_header_line {
  width: 1px;
  height: 25px;
  background-color: #fff;
  margin: 0 30px;
}

.syy_sdk {
  margin-right: 45px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: SourceHanSansSC-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  cursor: pointer;
  position: relative;
}

.syy_sdk:hover {
  opacity: 0.8;
}

.syy_sdk a {
  color: #fff;
}

.go_to_login:hover,
.go_to_reg:hover {
  opacity: 0.8;
}

.syy_sdk::after {
  content: "私有云SDK";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: -62px;
  top: -15px;
  width: 80px;
  height: 20px;
  line-height: 20px;
  background: #ec6860;
  border-radius: 8px 8px 8px 0px;
}

.header_down_item {
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
}

.header_down_item:hover {
  background-color: #f2f5fa;
}

.header_down_item span {
  padding-left: 5px;
}

.app_sao_code_box {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  top: 0;
  left: -197px;
}

.app_sao_code {
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app_sao_code img {
  width: 135px;
  height: 135px;
  margin-left: unset;
}

.app_sao_code_text {
  margin-top: 15px;
  font-size: 16px;
  color: #000;
}

#feedback_show:hover {
  opacity: 0.8;
}

.cando_consult_box {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.cando_consult_box .cando_consult {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 31px 32px 60px 29px;
  border: 1px solid #cccccc;
  border-radius: 20px;
  background: #fbfbfb;
}

.cando_consult_box .cando_consult .title {
  margin-bottom: 33px;
  font-size: 24px;
}

.cando_consult_box .cando_consult .close {
  position: absolute;
  top: 35px;
  right: 19px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.cando_consult_box .cando_consult .close .close_left,
.cando_consult_box .cando_consult .close .close_right {
  position: absolute;
  top: 50%;
  width: 18px;
  height: 1px;
  background-color: #333;
  transform: rotate(45deg);
}

.cando_consult_box .cando_consult .close .close_right {
  transform: rotate(-45deg);
}

.cando_consult_box .cando_consult .consult_members {
  font-weight: bold;
  color: #000000;
}

.cando_consult_box .cando_consult .consult_members li {
  margin-bottom: 28px;
}

.cando_consult_box .cando_consult .consult_members li:last-of-type {
  margin-bottom: 0;
}

.cando_consult_box .cando_consult .consult_members li .name {
  display: flex;
  align-items: center;
  font-size: 19px;
}

.cando_consult_box .cando_consult .consult_members li .name .line {
  width: 7px;
  height: 18px;
  margin-top: 2px;
  margin-right: 6px;
  background: #007cea;
}

.cando_consult_box .cando_consult .consult_members li .telephone,
.cando_consult_box .cando_consult .consult_members li .mailbox {
  margin-top: 14px;
  font-size: 16px;
}

.cando_consult_box .cando_consult .consult_members li .mailbox {
  margin-top: 11px;
}
</style>
