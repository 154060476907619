<template>
  <div class="batch_share_dwg">
    <!-- 使用已选择的 -->
    <el-dialog
      title="批量发送图纸" width="600px" class="had_chose"
      :visible.sync="had_chose"
      :modal-append-to-body="false" :close-on-click-modal="false"
    >
      <div class="contain">
        <div class="chose_num">已选图纸：{{ checked_file.length }}</div>
        <div class="file_box">
          <div class="file_item" v-for="(item, i) in checked_file" :key="i">
            <div class="file-name">
              <img
                :src="dwg_img.imgSrc1"
                v-if="item.type == 'file'"
              />
              <div
                class="file_name_word"
                :title="item.name"
                style="color: rgb(61, 71, 87)"
                v-if="!item.input"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="continueChoose">继续选择文件</el-button>
        <el-button type="primary" @click="sureSendFile">确定</el-button>
      </span>
    </el-dialog>

    <!-- 左侧展示所有图纸，用户自主选择 -->
    <el-dialog
      title="批量发送图纸" width="640px" class="batch_choose"
      :visible.sync="batch_choose"
      :modal-append-to-body="false" :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="contain2">
        <div class="all_file">
          <div class="title">我的云图纸：{{ my_drawings.length }}</div>
          <div class="file_box">
            <div class="file_item" v-for="(item, i) in my_drawings" :key="i">
              <el-checkbox
                @change="choseMyTableChange(item)"
                v-model="item.checked"
                v-if="!item.input"
                :class="{ 'active': item.checked }"
              >
                <div class="file-name">
                  <img
                    :src="dwg_img.imgSrc1"
                    v-if="item.type == 'file'"
                  />
                  <div
                    class="file_name_word"
                    style="color: rgb(61, 71, 87)"
                    v-if="!item.input"
                  >
                    <p :title="item.name">{{ item.name }}</p>
                    <p class="path" :title="item.cloud_path">{{ item.cloud_path }}</p>
                  </div>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="chose_file">
          <div class="title">已选图纸：{{ add_check_file.length }}</div>
          <div class="file_box">
            <div class="file_item" v-for="(item, i) in add_check_file" :key="i">
              <div class="file-name">
                <img
                  :src="dwg_img.imgSrc1"
                  v-if="item.type == 'file'"
                />
                <div
                  class="file_name_word"
                  style="color: rgb(61, 71, 87)"
                  v-if="!item.input"
                >
                  <p :title="item.name">{{ item.name }}</p>
                  <p class="path" :title="item.cloud_path">{{ item.cloud_path }}</p>
                </div>
              </div>
            </div>
            <p class="tips" v-show="add_check_file.length <= 0">请从左边选择需要发送的图纸</p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(batch_choose = false)">取消</el-button>
        <el-button type="primary" @click="sureSendFile" :disabled="add_check_file.length == 0 ? true : false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    allFiles: {},
  },
  data() {
    return {
      dwg_img: {
        imgSrc1: require('@/assets/images/cloud/file_cloud.png'),
      },
      add_check_file: [],
      checked_file: '',
      my_drawings: '',
      had_chose: false,
      batch_choose: false,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    continueChoose() {
      this.checkedItems.forEach(item => {
        this.my_drawings.forEach(item2 => {
          if (item2.parent_dir == item.parent_dir && item2.name == item.name && item2.id == item.id) {
            item2.checked = true;
            this.add_check_file.push(item2);
          }
        })
      })

      this.$store.state.batchCheckedItems = this.add_check_file;

      this.had_chose = false;
      this.batch_choose = true;
    },
    choseMyTableChange(obj) {
      if (obj.checked) {
        this.add_check_file.push(obj);
      } else {
        var new_checked_items = [];
        this.add_check_file.forEach((item) => {
          if (item.name == obj.name) {
            if (item.cloud_path != obj.cloud_path) {
              new_checked_items.push(item);
            }
          } else {
            new_checked_items.push(item);
          }
        });
        this.add_check_file = new_checked_items;
      }

      this.$store.state.batchCheckedItems = this.add_check_file;
    },
    sureSendFile() {
      if (this.had_chose && !this.batch_choose) {
        this.$parent.SEND_DWGS_MY('batch_hadChose');
      } else {
        this.$parent.SEND_DWGS_MY('batch');
      }

      if (this.had_chose) this.had_chose = false;
      if (this.batch_choose) this.batch_choose = false;
    },
  },
  watch: {
    showBatchShare: {
      handler(newVal) {
        if (newVal) {
          this.checked_file = this.checkedItems.filter(item => item.type == 'file');
          
          this.batch_choose = true;
        }
      }
    },
    allFilesNew: {
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          this.my_drawings = newVal.filter(item => item.type == 'file');
        }
      },
      deep: true
    },
    had_chose: {
      handler(newVal) {
        if (!newVal && !this.batch_choose) {
          this.$store.state.show_batch_share = false;
        }
      }
    },
    batch_choose: {
      handler(newVal) {
        if (!newVal && !this.had_chose) {
          this.add_check_file = [];
          this.$store.state.show_batch_share = false;
        }
      }
    },
    checkedItems: {
      handler(newVal) {
        if (newVal) {
          this.checked_file = this.checkedItems.filter(item => item.type != 'dir');
        }
      },
      deep: true,
    }
  },
  computed: {
    allFilesNew() {
      return JSON.parse(JSON.stringify(this.allFiles));
    },
    ...mapState({
      showBatchShare: state => state.show_batch_share,
      checkedItems: state => state.checkedItems,
      myDrawings: state => state.tableData_my_drawings,
    })
  }
}
</script>

<style lang="less" scoped>
  .batch_share_dwg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    
    .had_chose, .batch_choose {
      /deep/ .el-dialog {

        .el-dialog__header::after {
          width: 94%;
        }

        .el-dialog__body {
          padding: 0 20px!important;
        }

        .el-dialog__footer {
          padding: 17px 20px 23px;

          .el-button {
            padding: 10px 19px;

            .el-button--primary {
              margin-left: 15px;
            }
          }
        }

        .contain {

          .chose_num {
            margin: 18px 0 12px;
            font-size: 12px;
            color: #666666;
          }

          .file_box {
            width: 100%;
            max-height: 276px;
            border: 1px solid #E7E9EB;
            overflow-y: auto;
            
            .file_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 54px;
              line-height: 54px;
              font-size: 14px;

              .file-name {
                width: 100%;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }

    .had_chose {
      .file_name_word {
        float: left;
      }
    }

    .batch_choose {
      /deep/ .el-dialog {
        .contain2 {
          display: flex;
          
          .title {
            margin: 18px 0 12px;
            font-size: 12px;
            color: #666666;
          }

          .line {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: calc(100% - 64px);
            background: #E7E9EB;
          }

          .all_file {
            margin-right: 22px;

            .file_box {
              .file_item {
                padding: 0 7px;

                .el-checkbox {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  padding-left: 2px;
                  
                  &:hover, &.active {
                    background: #F0F4FC;
                  }

                  .el-checkbox__label {
                    flex: 1;
                    height: 100%;
                    padding-left: 0;
                    overflow: hidden;

                    .file-name {
                      display: flex;
                      align-items: center;
                      height: 100%;
                      padding-left: 8px;
                      margin-left: 0;
                      box-sizing: border-box;

                      img {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          .chose_file {

            .file_box {

              .file_item {
                .file-name {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  padding-right: 15px;

                  img {
                    margin-top: 0;
                  }
                }
              }

              .tips {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
              }
            }
          }

          .file_box {
            width: 286px;
            height: 388px;
            border: 1px solid #E7E9EB;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 6px;
              height: 10px;
            }

            .file_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 54px;
              line-height: 19px;
              font-size: 14px;

              .file-name {
                width: 100%;
                margin-left: 15px;

                .file_name_word {
                  p {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  .path {
                    color: #999;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .file_name_word {
      margin-left: 10px;
      color: #3d4757;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
</style>
