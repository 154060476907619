<template>
  <!-- 新建群 -->
  <el-dialog
    title="新建群"
    :visible.sync="$store.state.is_show_add_team"
    width="456px"
    :modal="true"
    :append-to-body="true"
    @close="handleAddTeamClose"
    class="my_team_diglog"
  >
    <div class="tanchuang-box" id="dwg_add_box" style="display: block">
      <div class="feedback_phone">
        <div class="feedback_phone_text">群名称：</div>
        <div class="ctb-input-tname">
          <input
            class="feedback_phont_input"
            type="text"
            maxlength="16"
            placeholder="请输入您要新建的群名称"
            autocomplete="off"
            v-model="create_name"
            @focus="is_show_el_tree = false"
            ref="createName"
            :autofocus="autofocus"
          />
        </div>
        <div class="ctb-word2">群成员</div>
        <div class="ctb-master-user">
          <div class="ctb-avatar">
            <img src="@/assets/images/team/avatar.png" />
          </div>
          <div id="create_team_master_account">{{ userinfo.user_name == "" || userinfo.user_name == null ? userinfo.account : userinfo.user_name }}</div>
        </div>
        <div class="ctb-btns">
          <div
            class="quxiao_btn_hover"
            id="cancel_create_team"
            @click="cancel_create_team"
          >
            取消
          </div>
          <div id="yes_create_team" @click="yes_create_team">创建</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>


import { create_team } from "@/api/get_cloud";

export default {
  name: "AddTeamView",
  data() {
    return {
      create_name: "",
      autofocus: true,
      userinfo: "",
    };
  },

  mounted() {
    var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userinfo != null) {
      this.userinfo = userinfo;
      this.create_name =
        userinfo.user_name == "" || userinfo.user_name == null
          ? userinfo.account + "的群"
          : userinfo.user_name + "的群";
    }
  },

  methods: {
    handleAddTeamClose() {
      this.$store.state.is_show_add_team = false;
      var userinfo = this.userinfo;
      if (userinfo != null || userinfo == "") {
        this.create_name =
          userinfo.user_name == "" || userinfo.user_name == null
            ? userinfo.account + "的群"
            : userinfo.user_name + "的群";
      }
    },

    cancel_create_team() {
      this.handleAddTeamClose();
    },

    yes_create_team() {
      if (this.create_name == '') { this.$msg.whiteerr('群名称不能为空') ; return }
      if (this.create_name.length > 40) { this.$msg.whiteerr('群名称不能超过40个字符'); return }
      var send = { 'tname': this.create_name }
      create_team(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
					this.$EventBus.$emit("getTeams");
          this.handleAddTeamClose();
				} else if (data.code == 99) {
					this.$msg.whiteerr('登录信息已过期，请重新登录')
				} else {
					this.$msg.whiteerr(data.msg)
				}
      })
    },
  },
};
</script>

<style lang="less" scoped>
.ctb-input-tname {
  margin-top: 10px;
}

.feedback_phont_input {
  width: 410px;
  height: 40px;
  outline: none;
  border: 1px solid #4383f0;
  border-radius: 2px;
  text-indent: 10px;
  font-size: 14px;
}

.ctb-word2 {
  line-height: 20px;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  margin-top: 60px;
}

.ctb-master-user {
  display: flex;
  flex-direction: revert;
  margin-top: 20px;
}

.ctb-avatar {
  width: 24px;
  height: 24px;
}

.ctb-avatar img {
  width: 100%;
  height: 100%;
}

#create_team_master_account {
  line-height: 24px;
  margin-left: 5px;
  font-size: 14px;
}

.ctb-btns {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

#cancel_create_team {
  border: 1px solid #ced0d1;
  color: #999;
  margin-left: 288px;
}

.ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

#yes_create_team {
  background-color: #4383f0;
  color: #fff;
  margin-left: 10px;
}
</style>