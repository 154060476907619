<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import utils from "./utils/utils";
export default {
  name: "App",
  data() {
    return {
      phoneurl: "https://m.wkcad.com",
      phoneurl_team_join: "/pages/myteam/join",
      phoneurl_mshare: "/pages/mshare/mshare",
      phoneurl_sdk: "/pages/sdk/selfcloud",
    };
  },

  mounted() {
    // console.log(process.env.VUE_APP_BASE_API, "VUE_APP_BASE_API");
    if (this.isMobile()) {
      console.log("移动端");
      // 在手机中打开PC端路由处理
      if (window.location.pathname == "/dwg") {
        console.log("这是dwg");
        let file_id = utils.getQueryVariable("file_id");
        let share_id = utils.getQueryVariable("share_id");
        let link_id = utils.getQueryVariable("link_id");
        let pos = utils.getQueryVariable("pos");
        if (share_id == "") {
          window.location.href = this.phoneurl + "/pages/dwg/dwg?file_id=" + file_id;
        } else {
          window.location.href =
            this.phoneurl +
            "/pages/dwg/dwg?share_id=" +
            share_id +
            (link_id != "" ? "&link_id=" + link_id : "") +
            (pos != "" ? "&pos=" + pos : "");
        }
      } else if (window.location.pathname == "/sync_demo2" || window.location.pathname == "/dwg3") {
        console.log("不进入PC端");
      } else if (window.location.pathname == "/sync_demo" || window.location.pathname == "/dwg2") {
        console.log("不进入PC端");
      } else if (window.location.pathname == "/cdf") {
        console.log("这是cdf");
        let file_id = utils.getQueryVariable("file_id");
        let share_id = utils.getQueryVariable("share_id");
        if (share_id == "") {
          window.location.href = this.phoneurl + "/pages/cdf/cdf?file_id=" + file_id;
        } else {
          window.location.href = this.phoneurl + "/pages/cdf/cdf?share_id=" + share_id;
        }
      } else if (window.location.pathname == "/mshare") {
        // 分享文件
        var share_id = utils.getQueryVariable("share_id");
        window.location.href = this.phoneurl + this.phoneurl_mshare + "?share_id=" + share_id;
      } else if (window.location.pathname == "/teamjion") {
        //加入团队
        var t_code = utils.getQueryVariable("t_code");
        window.location.href = this.phoneurl + this.phoneurl_team_join + "?t_code=" + t_code;
      } else if (window.location.pathname == "/privatecloudsdk") {
        window.location.href = this.phoneurl + this.phoneurl_sdk;
      } else {
        //这是其他页面
        if (
          window.location.href.indexOf("/l/") !== -1 ||
          window.location.href.indexOf("/i/") !== -1
        ) {
          // 短链接
          this.judge_address("phone");
        } else {
          window.location.href = this.phoneurl;
        }
      }
    } else {
      console.log("pc端");
      this.judge_address("pc");
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //短号url dwg,cdf识别
    judge_address(from) {
      let str = window.location.href;
      if (str.indexOf("/l/") !== -1) {
        let arr = str.split("/l/");
        let share_id = arr[1];
        if (str.indexOf("?") !== -1) {
          share_id = share_id.split("?")[0];
        }
        let link_id = utils.getQueryVariable("link_id");
        let pos = utils.getQueryVariable("pos");
        if (from == "phone") {
          window.location.href =
            this.phoneurl +
            "/pages/dwg/dwg?share_id=" +
            share_id +
            (link_id != "" ? "&link_id=" + link_id : "") +
            (pos != "" ? "&pos=" + pos : "");
        } else {
          var data = {};
          if (link_id == "" && pos == "") {
            data = { share_id: share_id };
          } else if (link_id != "" && pos != "") {
            data = { share_id: share_id, link_id: link_id, pos: pos };
          } else if (link_id != "" && pos == "") {
            data = { share_id: share_id, link_id: link_id };
          } else if (link_id == "" && pos != "") {
            data = { share_id: share_id, pos: pos };
          } else {
            data = { share_id: share_id, link_id: link_id, pos: pos };
          }
          this.$router.push({ path: "/dwg", query: data, replace: true });
        }
        return;
      } else if (str.indexOf("/i/") !== -1) {
        let arr = str.split("/i/");
        let share_id = arr[1];
        if (from == "phone") {
          window.location.href = this.phoneurl + "/pages/cdf/cdf?share_id=" + share_id;
        } else {
          this.$router.push({ path: "/cdf", query: { share_id: share_id }, replace: true });
        }
        return;
      }
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
}
</style>
