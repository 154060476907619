<template>
  <el-dialog
    title="意见反馈"
    :visible.sync="feedbackVisible1"
    width="600px"
    :modal="true"
    :append-to-body="true"
    @close="handleClose"
    :close-on-click-modal="false"
    :custom-class="is_dwg_page ? 'feedback-box-diaglog-dwg' : 'feedback-box-diaglog'"
  >
    <div class="tanchuang-box feedback-box" :class=" is_dwg_page ? 'feedback-box_dwg': ''">
      <div id="feedback_no" v-if="!is_success">
        <div class="ctb-line"></div>
        <div class="ctb-input-tname">
          <textarea
            id="feedback_input"
            placeholder="您的宝贵意见将是我们前行的不断动力，输入您的意见，我们用心倾听..."
            autofocus="autofocus"
            type="textarea"
            rows="2"
            autocomplete="off"
            maxlength="1024"
            validateevent="true"
            v-model="feedback_value"
          ></textarea>
        </div>
        <div class="feedback_phone">
          <div>联系方式</div>
          <input
            id="feedback_phont_input"
            type="text"
            maxlength="11"
            name="feedback_phont_input__"
            placeholder="请输入您的手机号码"
            v-model="feedback_phont_value"
          />
        </div>
        <div class="ctb-btns">
          <div id="feedback_qq">
            客服电话：400-600 2063
            <span></span>
            <a href="https://oc.wkcad.com/cloud/api/jump_qq" target="_blank">QQ在线客服</a>
            <p style="margin: 0; line-height: 1">（工作日：9-18点）</p>
          </div>

          <div
            class="quxiao_btn_hover btn_no"
            id="feedback_btn_no"
            @click="close_dialog"
          >
            取消
          </div>
          <div id="feedback_btn_yes" class="btn_yes" @click="sumbit_feedback">
            提交
          </div>
        </div>
      </div>
      <div id="feedback_yes" v-if="is_success">
        <div class="feedback_yes_main">
          <div class="feedback_yes_main_img">
            <img src="@/assets/images/pay/pay_success.png" alt="" />
          </div>
          <div class="feedback_yes_main_title">反馈成功</div>
          <div class="feedback_yes_main_text">
            感谢您的反馈，我们将尽快回复您
          </div>
          <div
            class="quxiao_btn_hover btn_no"
            id="feedback_yes_no"
            @click="close_dialog"
          >
            关闭
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import utils from "@/utils/utils";
import { sendMsg } from "@/api/user";

export default {
  name: "Feedback_page",
  props: ["feedbackVisible"],
  data() {
    return {
      feedbackVisible1: false,
      feedback_phont_value: "",
      feedback_value: "",
      is_success: false,
      is_dwg_page: false
    };
  },
  created() {
    this.feedbackVisible1 = this.$props.feedbackVisible;
  },
  mounted () {
    if (this.$route.path == '/dwgsee' || this.$route.path == '/dwg') {
      this.is_dwg_page = true
    } else {
      this.is_dwg_page = false
    }
  },
  methods: {
    close_dialog() {
      this.feedbackVisible1 = false;
      this.is_success = false;
      this.$emit("change_feedback_show", this.feedbackVisible1);
    },

    handleClose() {
      this.feedbackVisible1 = false;
      this.is_success = false;
      this.$emit("change_feedback_show", this.feedbackVisible1);
    },

    sumbit_feedback() {
      if (this.feedback_value == "") {
        this.$message.info("请输入您的反馈内容");
        return;
      }
      if (!utils.isPhoneNo(this.feedback_phont_value)) {
        this.$message.error("请输入正确的手机号码");
        return;
      }

      this.send_msg();
    },
    send_msg() {
      var data = {
        phone: this.feedback_phont_value,
        msg: this.feedback_value,
      };
      sendMsg(data).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.$message.error("登录信息已失效，请重新登录");
        } else if (data.code == 1) {
          this.$message.success("已成功发送，感谢您宝贵的意见反馈");
          this.is_success = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.feedback-box,
.tanchuang-box {
  width: 600px;
  border-radius: 5px;
  /* box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%); */
  background-color: #fff;
  z-index: 105;
}

.feedback-box_dwg {
  background-color: #454f61 !important;

}

.feedback-box {
  height: 335px;
}

.tanchuang-box .ctb-top {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tanchuang-box .tan-ctb-title {
  font-size: 15px;
  color: #333;
  height: 64px;
  line-height: 64px;
  padding-left: 20px;
}

.tanchuang-box .tanchuang_exit,
#feedback_box_exit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 15px;
}

.tanchuang-box .tanchuang_exit img,
#feedback_box_exit img {
  width: 100%;
  height: 100%;
}

.tanchuang-box .ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.tanchuang-box .ctb-word1 {
  font-size: 13px;
  color: #333;
  line-height: 20px;
  margin-top: 30px;
  margin-left: 20px;
}

.tanchuang-box .ctb-input-tname {
  margin-top: 30px;
  margin-left: 20px;
}

.tanchuang-box .ctb-input-tname textarea {
  width: 530px;
  outline: none;
  border-radius: 2px;
  font-size: 14px;
  color: #999;
  border: solid 1px #d8d8d8;
  padding: 13px;
  height: 120px;
  resize: none;
}

.tanchuang-box .ctb-input-tname textarea:focus {
  border: solid 1px #4383f0;
}

.tanchuang-box .ctb-word2 {
  line-height: 20px;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  margin-top: 60px;
  margin-left: 20px;
}

.tanchuang-box .ctb-master-user {
  display: flex;
  flex-direction: revert;
  margin-left: 20px;
  margin-top: 20px;
}

.tanchuang-box .ctb-btns {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

.tanchuang-box .ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

#feedback_btn_no,
.tanchuang-box .btn_no {
  border: 1px solid #ced0d1;
  color: #999;
}

.tanchuang-box .btn_yes,
#feedback_btn_yes {
  background-color: #4383f0;
  color: #fff;
  margin-left: 10px;
}

.tanchuang-box .btn_no:hover,
#feedback_btn_no:hover {
  color: #4383f0;
  border: solid 1px #4383f0;
}

.feedback_phone {
  display: flex;
  align-items: center;
  padding: 20px 0 0 20px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.feedback_phone input {
  width: 485px;
  margin-left: 20px;
  height: 30px;
  line-height: 30px;
  outline: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  text-indent: 13px;
  color: #333;
  font-size: 14px;
}

.feedback_phone input:focus {
  border: 1px solid #4383f0;
}

#feedback_qq {
  width: 408px;
  cursor: default;
  display: flex;
  align-items: center;
}

#feedback_qq a {
  color: #4383f0;
  text-decoration: none;
}

#feedback_qq a:hover {
  color: #4383f0;
}

#feedback_qq span {
  display: inline-block;
  width: 1px;
  height: 17px;
  background-color: #9fa7b2;
  margin: 0 8px;
}

#feedback_input,
#feedback_phont_input {
  font-family: MicrosoftYaHei;
}

.feedback_yes_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 30px);
}

.feedback_yes_main_title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333;
  margin-top: 20px;
}

.feedback_yes_main_text {
  margin-top: 15px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333;
}

#feedback_yes_no {
  margin-top: 24px;
  color: #fff;
  width: 80px;
  height: 24px;
  background-color: #454f61;
  border-radius: 2px;
  border: solid 1px #687689;
  line-height: 24px;
  text-align: center;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fefefe;
  user-select: none;
  cursor: pointer;
}

#feedback_yes {
  height: calc(100% - 64px);
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}
</style>