import { render, staticRenderFns } from "./upload_history.vue?vue&type=template&id=9766962e&scoped=true&"
import script from "./upload_history.vue?vue&type=script&lang=js&"
export * from "./upload_history.vue?vue&type=script&lang=js&"
import style0 from "./upload_history.vue?vue&type=style&index=0&id=9766962e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9766962e",
  null
  
)

export default component.exports