/**
 * 基于 axios 封装的请求模块
 */
import axios from 'axios'
//  import JSONbig from 'json-bigint'
// axios()
// axios.get()
// axios.post()

// 创建一个 axios 实例，说白了就是复制了一个 axios
// 我们通过这个实例去发请求，把需要的配置配置给这个实例来处理
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.withCredentials = true

const request = axios.create({
  baseURL: baseURL,
  // 定义后端返回的原始数据的处理
  // 参数 data 就是后端返回的袁术数据（未经处理 JSON 格式）
  transformResponse: [function (data) {
    try {
      // 转换成功，把结果返回
      //  return JSONbig.parse(data)
      return data
    } catch (err) {
      // 失败，把源数据返回给请求使用
      return data
    }
  }]
})

// 请求拦截器
request.interceptors.request.use(
  // 任何所有请求会经过这里
  // config 是当前请求相关的配置信息对象
  // config 是可以修改的
  function (config) {
    const USERTOKEN = JSON.parse(window.localStorage.getItem('USERTOKEN'))
    // 如果有登录用户信息，则统一设置 token
    if (USERTOKEN) {
      config.headers.Authorization = `Bearer ${USERTOKEN}`
      window.localStorage.setItem('USERTOKEN', JSON.stringify(USERTOKEN))
    }
    // 然后我们就可以在允许请求出去之前定制统一业务功能处理
    // 例如：统一的设置 token
    // 当这里 return config 之后请求在会真正的发出去
    return config
  },
  // 请求失败，会经过这里
  function (error) {
    return Promise.reject(error)
  }
)
// 响应拦截器

// 导出请求方法
export default request


// 封装get请求
export function get(obj) {
  return new Promise((resolve, reject) => {
      axios.get(obj.url, {
          params: obj.data
      }).then(res => {
          resolve(res)
      }).catch(err => {
          reject(err)
      })
  })
}

// 封装post请求
export function post(obj) {
  return new Promise((resolve, reject) => {
      axios.post(obj.url, qs.stringify(obj.data)).then(res => {
          resolve(res)
      }).then(err => {
          reject(err)
      })
  })
}

// 封装参数为formData的 post 请求
export function formPost(obj) {
  return new Promise((resolve, reject) => {
      axios.post(obj.url, obj.data).then(res => {
          resolve(res)
      }).then(err => {
          reject(err)
      })
  })
}
 // 谁要使用谁就加载 request 模块
 // import request from 'request.js'
 // request.xxx
 // request({
 //   method: ,
 //   url: ''
 // })