<template>
  <div class="masklayer" v-show="$store.state.mask"></div>
</template>

<script>
export default {
  name:'MaskLayer'
}
</script>

<style lang="less" scoped>
.masklayer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 51;
  width: 100%;
  height: 100%;
  margin: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}
</style>