<template>
  <div class="team-html">
    <AddTeamView></AddTeamView>
    <TeamMemberView ref="childMethods_getMember"></TeamMemberView>
    <TeamSetView ref="childMethods_setDataValue"></TeamSetView>
    <AddCloudFileView ref="childMethods_getCloudLists"></AddCloudFileView>
    <div class="no-team" v-if="$store.state.my_teams.length == 0">
      <div class="no-team-center">
        <div class="no-team-img">
          <img src="@/assets/images/team/no_team.png" />
        </div>
        <div class="no-team-word1">暂无群</div>
        <div class="no-team-word2">你创建的与你加入的群将会出现在这里</div>
        <div id="create_newteam_btn" @click="$store.state.is_show_add_team = true">创建群</div>
      </div>
    </div>
    <div class="team-box" @click.stop="is_show_right_menus = false" v-if="$store.state.my_teams.length != 0">
      <div class="team_flex">
        <div class="team-l">
          <div
            class="tl-top"
            id="create_newteam_btn2"
            @click="$store.state.is_show_add_team = true"
          >
            <div class="create-team-avatar">
              <img src="@/assets/images/team/create_team.png" />
            </div>
            <div class="create-newteam-btn2-word">新建群</div>
          </div>
          <div id="team_items" class="team-items">
            <div
              class="team-item"
              :class="item.active ? 'team-item-active' : ''"
              v-for="(item, i) in $store.state.my_teams"
              :key="i"
              @click="check_items_item(item.t_id)"
              @contextmenu.prevent="show_right_menus($event, item)"
            >
              <div class="team-avatar">
                <img src="@/assets/images/team/team_avatar.png" />
              </div>
              <div class="team-name" :title="item.tname">{{ item.tname }}</div>
            </div>
          </div>
          <div
            class="team-menu-box"
            :style="
              'left:' +
              right_menus_left +
              'px; top:' +
              right_menus_top +
              'px;z-index:5'
            "
            v-if="is_show_right_menus"
          >
            <div
              class="menu-item"
              id="menu_team_url"
              v-show="is_uid"
              @click.stop="right_menus_click('add')"
            >
              添加成员
            </div>
            <div
              class="menu-item"
              id="menu_team_set"
              v-show="is_uid"
              @click.stop="right_menus_click('setting')"
            >
              群设置
            </div>
            <div
              class="menu-item"
              id="menu_team_rname"
              v-show="is_uid"
              @click.stop="right_menus_click('update')"
            >
              重命名
            </div>
            <div
              class="menu-item"
              id="menu_team_break"
              v-show="is_uid"
              @click.stop="right_menus_click('del')"
            >
              解散
            </div>
            <div
              class="menu-item"
              id="menu_team_break"
              v-show="!is_uid"
              @click.stop="right_menus_click('quit')"
            >
              退出群
            </div>
          </div>
        </div>
        <div class="team-line"></div>
        <div class="team-r">
          <div class="team_update_jilu_box">
            <div class="team_update_jilu">
              <div class="team_update_jilu_title">
                <span>阿萨的阿萨的阿萨的撒.dwg</span>
                <img
                  id="close_team_update_jilu_box"
                  src="@/assets/images/common/login_x.png"
                />
              </div>
              <div class="team_update_jilu_line"></div>

              <div class="team_update_more">更多信息</div>

              <div class="team_update_jilu_items">
                <div class="team_update_jilu_item">
                  <div class="team_update_jilu_item_row">
                    <div class="team_update_jilu_left">上传者</div>
                    <div class="team_update_jilu_right">123456789</div>
                  </div>
                  <div class="team_update_jilu_item_row">
                    <div class="team_update_jilu_left">上次修改</div>
                    <div class="team_update_jilu_right">123456789</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="team-cloud-btns">
            <input
              id="team_upload_file_input"
              style="
                position: absolute;
                opacity: 0;
                width: 1px;
                height: 1px;
                cursor: pointer;
              "
              type="file"
              multiple="multiple"
              name=""
              accept=".dwg"
            />
            <!-- <div id="team_upload_btn" class="team-cloud-btn">
					<div class="team-cloud-btn-img"><img src="@/assets/images/cloud/cloud_adddir.png"></div>
					<div id="team_upload_file" class="team-cloud-btn-name">上传</div>
				</div> -->
          </div>
          <div class="team-r-top min_width300">
            <div class="trt-l trt-l-top">文件</div>
            <div class="trt-r">
              <div class="trtr-word1">
                共<span id="r_team_p_num">{{
                  $store.state.get_team_data != null
                    ? $store.state.get_team_data.p_num
                    : 1
                }}</span
                >人
              </div>
              <div class="trt-add-user" @click.stop="right_menus_click('add')">
                <img
                  id="trt_add_user_img"
                  src="@/assets/images/team/add_user.png"
                />
                <div id="trt_add_user">添加成员</div>
              </div>
              <div class="trtr-word2"></div>
              <div id="set_team_btn" @click.stop="right_menus_click('setting')">
                <img src="@/assets/images/team/set.png" />
              </div>
            </div>
          </div>
          <div class="team-line2"></div>
          <div class="team-top-top min_width400">
            <div class="team-top-top-head">
              <div class="team-top-top-head-left">
                <div>置顶内容</div>
                <span id="team-top-top-bianji" @click="edit_announcement_isshow"
                  >编辑</span
                >
                <span
                  id="team-top-top-open"
                  @click="
                    (is_show_text1 = !is_show_text1),
                      (is_show_group_announcement_input = false)
                  "
                  >{{ is_show_text1 ? "收起" : "展开" }}</span
                >
              </div>
              <div class="team-top-top-head-right">
                <div class="team-top-top-head-right-tname">{{ group_announcement_time != "" ? group_announcement_mu_name : ''}}</div>
                <div class="team-top-top-head-right-text">
                  {{
                    group_announcement_time != ""
                      ? "更新于 " + group_announcement_time
                      : ""
                  }}
                </div>
                <div class="team-top-top-head-right-ttime"></div>
              </div>
            </div>
            <div
              class="team-top-top-text"
              v-if="
                group_announcement == '' &&
                !is_show_group_announcement_input &&
                !is_show_text1
              "
            >
              点击
              <span @click="edit_announcement_isshow">添加</span>
              群置顶公告让成员关注
            </div>
            <div
              class="team-top-top-text1"
              v-if="!is_show_group_announcement_input && is_show_text1"
              v-html="group_announcement.replace(/\n/g, '<br>')"
            >
            </div>
            <div
              class="team-top-top-input"
              v-if="is_show_group_announcement_input"
            >
              <el-input 
                type="textarea"
                v-model="group_announcement"
                :rows="2"
                maxlength="1024"
                id="team-top-top-input-text"
                autofocus="true"
                resize="none"
                ref="announcement_input"
                ></el-input>
              <div
                class="team-top-top-no quxiao_btn_hover"
                @click="update_group_announcement_btn_no"
              >
                取消
              </div>
              <div
                class="team-top-top-yes"
                @click="update_group_announcement_btn_yes"
              >
                确定
              </div>
            </div>
          </div>
          <div class="team-r-top min_width200" style="margin-top: 10px">
            <div class="trt-l">群文件</div>
            <div class="trt-r">
              <el-dropdown trigger="click" @command="upload_item_btn">
                <div class="team_cloud_right_up" id="team_upload_btn">
                  <img
                    src="@/assets/images/cloud/file_shangchuan1.png"
                    alt=""
                  />
                  <span>上传</span>
                </div>
                <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-160">
                  <el-dropdown-item
                    :command="1"
                    :type="it.type"
                    v-for="(it, index) in upload_btn_items"
                    :key="index"
                    >{{ it.text }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="team-line3"></div>
          <div class="team-file-lists">
            <div id="team-file-head">
              <div class="team-file-first">
                <div class="team-file-first-name">
                  <div>名称</div>
                </div>
                <div class="team-file-first-time">修改时间</div>
                <div class="team-file-first-size">文件大小</div>
                <div class="team-file-first-do caozuo-box-team"></div>
              </div>
            </div>
            <div
              class="teamdfile_list_no"
              v-if="$store.state.my_team_files.length == 0"
            >
              <div class="teamfile_list_no">
                <div class="teamfile_list_no_box">
                  <img src="@/assets/images/team/no_file.png" alt="" />
                  <div class="teamfile_list_no_th">暂无文件</div>
                  <!-- <div class="teamfile_list_no_text">你创建的与你加入的团队将会出现在这里</div> -->
                  <!-- <div id="team_nofile_upload_btn">上传文件</div> -->
                </div>
              </div>
            </div>

            <div
              class="team-file-list"
              v-if="$store.state.my_team_files.length != 0"
            >
              <div
                class="team-file-item"
                v-for="(item, i) in $store.state.my_team_files"
                :key="i"
                @mouseenter="show_share_btn_team_file(i)"
                @mouseleave="hide_share_btn_team_file(i)"
              >
                <div class="team-file-name">
                  <div
                    class="team-file-name-img"
                    @click="open_dwg_team(item.name)"
                  >
                    <img src="@/assets/images/cloud/file.png" />
                  </div>
                  <div
                    class="team-file-name-word"
                    @click="open_dwg_team(item.name)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div class="team-file-time">{{ item.mtime }}</div>
                <div class="team-file-size">{{ item.size }}</div>
                <div class="caozuo-box caozuo-box-team">
                  <div class="file-more">
                    <el-dropdown
                      trigger="click"
                      @command="dropdown_item_btn_team"
                    >
                      <span class="el-dropdown-link">
                        <img src="@/assets/images/cloud/more.png" />
                      </span>
                      <el-dropdown-menu
                        slot="dropdown"
                        class="el-dropdown-menu-160"
                      >
                        <el-dropdown-item
                          :command="item"
                          :type="it.type"
                          :index="i"
                          v-for="(it, index) in dropdown_items"
                          :key="index"
                          :disabled="
                            it.is_gm == false && !is_uid ? true : false
                          "
                          >{{ it.text }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div style="display: block" v-show="item.is_show_share">
                    <div
                      class="file-share"
                      title="生成网页链接发送给他人查看"
                      @click="team_send_file_share(item)"
                    >
                      发送图纸
                    </div>
                    <div
                      title="以PDF图框分页游览图纸"
                      class="file-pdf"
                      :class="item.pdf == 1 ? 'file-pdf-active' : ''"
                      style="display:none"
                    >
                      <div class="file-pdf-process"></div>
                      PDF
                    </div>
                    <div
                      class="team-file-collection"
                      title="收藏当前图纸"
                      @click="collect_cur_dwg_team(item, i)"
                      style="display:none"
                    >
                      <img
                        :src="
                          item.is_collect == 0 ? is_collect_0 : is_collect_1
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_teams,
  get_team_data,
  openTeamfile,
  cancel_collect_team_cloudfile,
  collect_team_cloudfile,
  update_team_name,
  break_team,
  myteam_deleteFile,
  get_download_url,
  update_ttop,
} from "@/api/get_cloud";
import utils from "@/utils/utils";

import AddTeamView from "./components/add_team.vue";
import TeamMemberView from "./components/member.vue";
import TeamSetView from "./components/team_setting.vue";
import AddCloudFileView from "./components/add_cloudfile.vue";
import Global from '@/views/dwg/global'

export default {
  name: "My_team",
  components: {
    AddTeamView,
    TeamMemberView,
    TeamSetView,
    AddCloudFileView,
  },
  data() {
    return {
      is_collect_0: require("@/assets/images/cloud/collect.png"),
      is_collect_1: require("@/assets/images/cloud/icon_richeng_star.png"),
      teams: [],
      teamFiles: [],
      is_show_right_menus: false,
      is_uid: false, //是否为群创建者，true为是
      is_show_group_announcement_input: false, //是否显示群公告输入
      is_show_text1: false, //是否展开
      group_announcement: "",
      group_announcement_time: "",
      group_announcement_mu_name: '',
      right_menus_top: 0,
      right_menus_left: 0,
      dropdown_items: [
        { text: "打开", type: "open", is_gm: true },
        // { text: "收藏", type: "collection", is_gm: true },
        { text: "下载", type: "down", is_gm: true },
        { text: "删除", type: "del", is_gm: false },
      ],
      upload_btn_items: [
        // {
        //   text: "文件",
        //   type: "upload",
        //   img: require("@/assets/images/team/cc-file.png"),
        // },
        { text: "添加云盘文件", type: "add_cloud", img: "el-icon-cloudy" },
      ],
    };
  },
  created() {},

  mounted() {
    //获取我的群
    this.getTeams();
    var that = this;
    // 监听切换左边tab
    this.$EventBus.$off("getTeams");
    this.$EventBus.$on("getTeams", function () {
      that.getTeams();
    });
  },

  computed: {
    my_team_files() {
      return this.$store.state.my_team_files;
    },
  },

  methods: {
    getTeams() {
      get_teams().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.login_expired_team();
          return;
        } else if (data.code == 1) {
          var items = data.team_datas;
          if (items.length == 0) {
            this.$store.state.cur_checke_tid = ''
          }
          items.forEach((item, index) => {
            item.active = false;
            if (this.$store.state.cur_checke_tid != "") {
              if (item.t_id == this.$store.state.cur_checke_tid) {
                item.active = true;
                item.right_action = false;
                this.$store.state.cur_checke_tid = item.t_id;
                this.get_team_t_id(item.t_id);
              }
            } else {
              if (index == 0) {
                item.active = true;
                this.$store.state.cur_checke_tid = item.t_id;
                this.get_team_t_id(item.t_id);
              }
            }
          });
          this.$store.state.my_teams = data.team_datas;
        }
      });
    },

    show_share_btn_team_file(i) {
      this.$store.state.my_team_files[i].is_show_share = true;
      this.$store.commit("updateMyTeamFiles", this.$store.state.my_team_files);
    },

    hide_share_btn_team_file(i) {
      this.$store.state.my_team_files[i].is_show_share = false;
      this.$store.commit("updateMyTeamFiles", this.$store.state.my_team_files);
    },

    //登录过期，回到首页
    login_expired_team() {
      this.$msg.whiteerr('登录信息已过期，请重新登陆')
      utils.del_localStorae();
      this.$router.push("/");
    },

    // 上传文件按钮选择
    upload_item_btn(item, event) {
      var type = event.$attrs.type;
      if (type == "upload") {
        console.log('')
      } else if (type == "add_cloud") {
        // 先判断我的图纸是否已拉取
        this.$store.state.is_show_team_add_cloudfile = true;
        this.$refs.childMethods_getCloudLists.getCloudLists();
      }
    },

    check_items_item(t_id) {
      this.is_show_right_menus = false;
      this.$store.state.my_teams.forEach((item) => {
        item.active = false;
        if (item.t_id == t_id) {
          item.active = true;
          this.get_team_t_id(t_id);
          this.$store.state.cur_checke_tid = t_id;
        }
      });
    },

    get_team_t_id(t_id) {
      var send = {
        t_id,
      };
      this.is_show_group_announcement_input = false;
      get_team_data(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$store.state.get_team_data = data.team_data;
          this.set_group_announcement(data.team_data);
          var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
          if (userinfo.uid == this.$store.state.get_team_data.mu_id) {
            this.is_uid = true;
          } else {
            this.is_uid = false;
          }
          var new_files = [];
          var files = data.team_data.files;
          files.forEach((item) => {
            item.is_show_share = false;
            item.size = utils.convert_size_MB(item.size);
            item.mtime = utils.convert_date_day(item.mtime)
            new_files.push(item);
          });
          this.$store.state.my_team_files = new_files;
        }
      });
    },

    //群公告渲染
    set_group_announcement(data) {
      this.group_announcement = data.ttop;
      this.group_announcement_time = data.ttop_time != '' ? utils.convert_date_day(data.ttop_time) : data.ttop_time;
      this.group_announcement_mu_name = data.mu_name;
      if (this.group_announcement != "") {
        this.is_show_text1 = true;
      } else {
        this.is_show_text1 = false;
      }
    },

    // 显示编辑公告输入框
    edit_announcement_isshow() {
      this.is_show_group_announcement_input =
        !this.is_show_group_announcement_input;
      if (this.is_show_group_announcement_input) {
        this.is_show_text1 = true;
      }
    },

    //编辑群公告取消
    update_group_announcement_btn_no() {
      this.is_show_group_announcement_input = false;
      this.group_announcement = this.$store.state.get_team_data.ttop;
    },

    //编辑群公告确定
    update_group_announcement_btn_yes() {
      if (this.group_announcement == "") {
        this.is_show_group_announcement_input = false;
        return;
      }
      var send = {
				't_id': this.$store.state.get_team_data.t_id,
				"ttop": this.group_announcement
			}
      update_ttop(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          this.is_show_group_announcement_input = false;
        } else if (res.code == 99) {
          this.login_expired_team()
				} else {
          this.$msg.whiteerr('服务器繁忙')
				}
      })

    },

    open_dwg_team(filename) {
      var params = {
        t_id: this.$store.state.cur_checke_tid,
        filename: filename,
        is_mobile: "0",
      };
      openTeamfile(params).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var f_id = data.f_id;
          let pathInfo = this.$router.resolve({
            path: "/dwg",
            query: {
              file_id: f_id,
            },
          });
          window.open(pathInfo.href, "_blank");
        }
      });
    },

    //收藏
    collect_cur_dwg_team(item, i) {
      this.team_file_collect(item, i);
    },

    //团队发送文件
    team_send_file_share(item) {
      this.$store.state.cur_share_data = item;
      this.$store.state.is_show_share_box = true;
      this.$store.state.share_from = "myteam";
      this.$EventBus.$emit("showShareBox");
    },

    //文件列表操作
    dropdown_item_btn_team(item, event) {
      var type = event.$attrs.type;
      var index = event.$attrs.index;
      if (type == "open") {
        //打开
        this.open_dwg_team(item.name);
      } else if (type == "del") {
        //删除
        this.show_delFile_box_tem(item, index);
      } else if (type == "collection") {
        //收藏
        this.team_file_collect(item, index);
      } else if (type == "down") {
        //下载
        this.down_teamFile(item.name);
      }
    },

    //下载
    down_teamFile(name) {
      var send = {
        t_id: this.$store.state.get_team_data.t_id,
        filename: name,
      };
      get_download_url(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          if (this.isIE_team()) {
            // IE
            window.open(Global.httpsUrl + "/cloud/myteam/download?dl_id=" + data.dl_id, "_blank");
          } else {
            let a = document.createElement("a"); // 创建a标签
            let e = document.createEvent("MouseEvents"); // 创建鼠标事件对象
            e.initEvent("click", false, false); // 初始化事件对象
            a.href = Global.httpsUrl + "/cloud/myteam/download?dl_id=" + data.dl_id; // 设置下载地址
            a.download = ""; // 设置下载文件名
            a.dispatchEvent(e);
          }
        } else if (data.code == 99) {
          this.login_expired_team();
        }
      });
    },

    isIE_team() {
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        return true;
      } else {
        return false;
      }
    },

    //显示删除确定框
    show_delFile_box_tem(item, i) {
      var text = "文件";
      if (item.type == "dir") {
        text = "文件夹";
      }
      this.$confirm("此操作将删除该" + text + ", 是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "alert_del_btn",
        customClass: "cover_messageBox",
      })
        .then(() => {
          this.delfile_teamFile(item.parent_dir, item.name, i);
        })
        .catch(() => {
          // this.$message.info("已取消删除");
        });
    },

    // 删除文件
    delfile_teamFile(parent_dir, filename, i) {
      var p = parent_dir + "/" + filename;
      p = p.replace("//", "/");
      var send = {
        p: p,
      };
      var __this = this;
      myteam_deleteFile(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.login_expired_my();
        } else if (data.code == 1) {
          __this.$store.state.my_team_files.splice(i, 1);
          // __this.$message.success("删除成功");
          __this.$msg.white('删除成功')
        }
      });
    },

    // 收藏
    team_file_collect(item, i) {
      var send1 = {
        t_id: this.$store.state.cur_checke_tid,
        filename: item.name,
      };
      if (item.is_collect == 0) {
        collect_team_cloudfile(send1).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.login_expired_team();
          } else if (data.code == 1) {
            this.$store.state.my_team_files[i].is_collect = 1;
            // this.$message.success("已成功收藏");
          } else if (data.code == 2) {
            // this.$message.info(data.msg);
          }
        });
      } else {
        cancel_collect_team_cloudfile(send1).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.login_expired_team();
          } else if (data.code == 1) {
            this.$store.state.my_team_files[i].is_collect = 0;
            // this.$message.info("已取消收藏");
          } else if (data.code == 2) {
            // this.$message.info(data.msg);
          }
        });
      }
    },

    // 群列表操作
    right_menus_click(type) {
      if (type == "add") {
        this.$store.state.is_show_member = true;
        this.$nextTick(() => {
          this.$refs.childMethods_getMember.getTeamUsers();
        });
      } else if (type == "setting") {
        this.$store.state.is_show_team_setting = true;
        this.$refs.childMethods_setDataValue.setDataValue();
      } else if (type == "update") {
        this.show_updateName_box();
      } else if (type == "del") {
        //解散群
        this.show_exit_team_box("break");
      } else if (type == "quit") {
        //退出群
        this.show_exit_team_box("quit");
      }
      this.is_show_right_menus = false;
    },

    // 右键显示群列表菜单
    show_right_menus(event, data) {
      var X = event.clientX;
      var Y = event.clientY;
      this.right_menus_top = Y;
      this.right_menus_left = X;
      this.is_show_right_menus = true;
      var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
      if (userinfo.uid == data.mu_id) {
        this.is_uid = true;
      } else {
        this.is_uid = false;
      }

      this.$store.state.my_teams.forEach((item) => {
        item.active = false;
        if (item.t_id == data.t_id) {
          item.active = true;
          this.get_team_t_id(data.t_id);
          this.$store.state.cur_checke_tid = data.t_id;
        }
      });
    },

    //显示 解散 退出群
    show_exit_team_box(type) {
      var __this = this;
      var btnText = type == "quit" ? "退出群" : "解散群";
      var text =
        type == "quit"
          ? "确认退出" + this.$store.state.get_team_data.tname
          : "确认解散" + this.$store.state.get_team_data.tname;
      var str = `<div class="exit_team_tsb-word1"><span id="tsb_word3">${text}</span></div>`;
      var exit_text =
        type == "quit"
          ? "退出后，该群的所有内容你将查看不到。"
          : "解散后，该群的所有内容将被删除。";
      var str1 = `<div class="exit_team_tsb-word2">${exit_text}</div>`;
      this.$alert(str + str1, btnText, {
        showCancelButton: true,
        closeOnPressEscape: true,
        closeOnClickModal: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: btnText,
        cancelButtonText: "取消",
        confirmButtonClass: "alert_del_btn",
        customClass: "cover_messageBox",
      })
        .then(() => {
          __this.$nextTick(() => {
            __this.exit_team(type);
          });
        })
        .catch(() => {
          // this.$message.info("已取消删除");
        });
    },

    exit_team(type) {
      var send = {
        type: type,
        t_id: this.$store.state.get_team_data.t_id,
      };

      break_team(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var text = type == "quit" ? "已成功退出群" : "已成功解散群";
          this.$msg.white(text)
          this.getTeams();
          if (this.$store.state.is_show_team_setting) {
            this.$store.state.is_show_team_setting = false;
          }
        } else if (data.code == 99) {
          this.login_expired_team();
        } else {
          this.$msg.whiteerr(data.msg)

        }
      });
    },

    //群重命名
    show_updateName_box() {
      var that = this;
      this.$prompt("", "重命名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputPattern: /^((?![\\|\\ \\"\\?\\>\\<\\:\\.\\'\\/\\*\\]).)*$/,
        inputErrorMessage: "不能使用特殊字符或空格",
        customClass: "updateName_messageBox",
        inputValue: that.$store.state.get_team_data.tname,
      })
        .then(({ value }) => {
          if (value == "" || value == null) {
            that.$msg.whiteerr('新名称不能为空')
            return;
          }
          that.updateTeamName(value);
        })
        .catch(() => {
          // that.$message.info("取消输入");
        });
    },

    updateTeamName(new_team_name) {
      var t_id = this.$store.state.get_team_data.t_id;
      var send = {
        t_id: t_id,
        new_team_name: new_team_name,
      };

      update_team_name(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var arr = this.$store.state.my_teams;
          arr.map((it) => {
            if (it.t_id == t_id) {
              it.tname = new_team_name;
            }
          });
          if (this.$store.state.get_team_data.t_id == t_id) {
            this.$store.state.get_team_data.tname = new_team_name;
          }
          this.$msg.white('修改成功')
        } else if (data.code == 2) {
          this.$msg.whiteerr(data.msg)
        } else if (data.code == 3) {
          this.$msg.whiteerr(data.msg)
        } else if (data.code == 99) {
          this.login_expired_team();
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.el-dropdown-menu-160 {
  width: 160px;
}

.el-dropdown-menu-160 li {
  height: 30px;
  line-height: 30px;
}

.team-html {
  width: 100%;
}

.no-team {
  width: 100%;
  height: 100%;
}

.no-team-img {
  width: 110px;
  height: 110px;
}

.no-team-img img {
  width: 100%;
}

.no-team-word1 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}

.no-team-word2 {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

#create_newteam_btn {
  width: 140px;
  height: 42px;
  /* background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%); */
  background-color: #4383f0;
  border-radius: 4px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
  cursor: pointer;
}

.team-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.team-l {
  width: 230px;
  height: 100%;
  overflow: hidden;
}

.tl-top {
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.create-team-avatar {
  width: 36px;
  height: 36px;
  margin-top: 15px;
  margin-left: 17px;
}

.create-team-avatar img {
  width: 36px;
  height: 36px;
}

.create-newteam-btn2-word {
  font-size: 14px;
  color: #333;
  height: 66px;
  line-height: 66px;
  margin-left: 10px;
}

.team-line {
  width: 1px;
  height: 760px;
  background-color: #e7e7e7;
}

.team-r {
  width: calc(100% - 260px);
  margin-left: 24px;
  height: 100%;
  overflow: auto;
  position: relative;
}

.team-item {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.team-avatar {
  width: 36px;
  height: 100%;
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.team-name {
  width: 160px;
  font-size: 14px;
  color: #333;
  height: 66px;
  line-height: 66px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-item-active {
  background-color: #f0f4fc;
}

.team-cloud-btns {
  width: 100%;
  /* height: 65px; */
  display: flex;
  flex-direction: row;
  /* margin-top: 19px; */
}

.team-cloud-btn {
  width: 90px;
  height: 42px;
  line-height: 42px;
  border-radius: 4px;
  border: solid 1px #979797;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.team-cloud-btn-img {
  width: 16px;
  height: 16px;
  margin-left: 15px;
  margin-top: 13px;
}

.team-cloud-btn-img img {
  width: 100%;
}

.team-cloud-btn-name {
  font-size: 14px;
  color: #333;
  margin-left: 5px;
}

#team_items {
  height: calc(100% - 76px);
  overflow: auto;
}

#team_items .el-dropdown {
  opacity: 0;
}

.no-team-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.team_flex {
  display: flex;
  width: 100%;
  height: 100%;
}

.team_update_jilu_box {
  position: fixed;
  right: 0;
  top: 57px;
  width: 280px;
  height: calc(100% - 57px);
  z-index: 9;
  background-color: #fff;
  border-left: 1px solid #e7e7e7;
  display: none;
}

.team_update_jilu_line {
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
}

.team_update_jilu_item_row {
  display: flex;
  align-items: center;
}

.team_update_jilu_item_row div {
  font-size: 14px;
  color: #666;
}

.team_update_jilu {
  width: 90%;
  margin-left: 5%;
}

.team_update_jilu_title {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
}

.team_update_jilu_title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team_update_jilu_item {
  height: 30px;
  line-height: 30px;
}

.team_update_more {
  padding: 20px 0 15px 0;
  color: #333;
  font-size: 15px;
}

.team_update_jilu_title img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.team_update_jilu_left {
  width: 80px;
}
.min_width400 {
  min-width: 400px !important;
}

.min_width300 {
  min-width: 300px !important;
}

.min_width100 {
  min-width: 100px !important;
}

.min_width200 {
  min-width: 200px !important;
}

.team-r-top {
  position: relative;
  height: 30px;
  margin-top: 20px;
  margin-right: 12px;
  line-height: 30px;
}

.trt-l {
  font-size: 15px;
  color: #4383f0;
  float: left;
}

.trt-r {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trtr-word1 {
  font-size: 12px;
  color: #666;
  cursor: pointer;
}

.trtr-word2 {
  height: 20px;
  width: 1px;
  background-color: #979797;
  margin-left: 20px;
  margin-right: 20px;
}

#set_team_btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

#set_team_btn img {
  width: 100%;
  height: 100%;
}

.team-line2 {
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 8px;
}

.team-line3 {
  width: 98.5%;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 8px;
}

.trt-add-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trt-add-user img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

#trt_add_user {
  font-size: 12px;
  color: #4383f0;
  cursor: pointer;
  margin-left: 10px;
}

.team-top-top {
  padding-top: 20px;
}

.team-top-top-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #666;
  padding-bottom: 10px;
}

.team-top-top-text,
.team-top-top-text1 {
  color: #666;
  font-size: 14px;
  padding-bottom: 10px;
}

.team-top-top-text span {
  font-size: 14px;
  color: #4383f0;
  cursor: pointer;
}

.team-top-top-head span {
  font-size: 14px;
  color: #4383f0;
  padding-left: 10px;
  cursor: pointer;
}

/deep/ .team-top-top-input #team-top-top-input-text:focus {
  outline: none !important;
  border: solid 2px #4383f0;
}

/deep/ .team-top-top-input #team-top-top-input-text:hover {
  border: solid 2px #4383f0;
}

/deep/ .team-top-top-input #team-top-top-input-text {
  font-size: 14px;
  color: #999;
  border: solid 2px #999;
  width: 100%;
  padding: 10px;
  height: 120px;
  resize: none;
}

.team-top-top-input {
  padding-bottom: 10px;
  position: relative;
  width: calc(100% - 24px);
  height: 120px;
}

.team-top-top-yes {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  background-color: #4383f0;
  border: solid 1px #4383f0;
  border-radius: 2px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #ffffff;
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.team-top-top-yes:hover,
.team-top-top-no:hover {
  opacity: 0.6;
}

.team-top-top-no {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  border-radius: 2px;
  border: solid 1px #ced0d1;
  font-size: 14px;
  letter-spacing: 1px;
  color: #999999;
  position: absolute;
  right: 90px;
  bottom: 20px;
}

.team-top-top-head-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
}

.team-top-top-head-right {
  display: flex;
  align-items: center;
  font-style: 14px;
  color: #999;
  padding-right: 20px;
}

.team-top-top-head-right-text {
  padding-left: 6px;
  padding-right: 6px;
  color: #999;
  font-size: 14px;
}

.team-top-top-head-right-tname,
.team-top-top-head-right-ttime {
  font-size: 14px;
  color: #999;
}

.team-up-tan-box {
  width: 160px;
  /* height: 138px; */
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(137, 136, 143, 0.16),
    0px 1px 11px 0px rgba(115, 115, 115, 0.15);
  border: solid 1px #e7e7e7;
  position: absolute;
  top: 22%;
  right: 27.5%;
  z-index: 6;
  display: none;
}

.team-up-tan-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 8px;
  width: 144px;
}

.team-up-tan-file {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team-up-tan-item {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 26px;
  font-size: 12px;
  color: #666666;
  width: 144px;
  margin: 10px;
}

.team-up-tan-item:hover {
  background-color: #f0f4fc;
  color: #4383f0;
}

.team-jion-main-file-list .file-item {
  position: relative;
  height: 32px;
  line-height: 32px;
}

.team-up-tan-item-img {
  width: 20px;
  height: 20px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-up-tan-item span {
  padding-left: 11px;
}

.item-gouxuan {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.trt-l-top {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

#team_upload_btn {
  align-items: center;
  font-size: 14px;
}

.team-file-first {
  height: 54px;
  line-height: 54px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.team-file-first-name {
  width: 50%;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  position: relative;
  font-weight: normal;
}

.team-file-first-time {
  width: 20%;
  font-size: 14px;
  color: #333;
  font-weight: normal;
}

.team-file-first-size {
  width: 15%;
  font-size: 14px;
  color: #333;
  font-weight: normal;
}

.team-file-first-do {
  width: 15%;
}

.team-file-item {
  height: 54px;
  line-height: 54px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
}

.team-file-item:hover {
  background-color: #f0f4fc;
}

.team-file-name {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.team-file-name-img {
  display: flex;
  align-items: center;
}

.team-file-name-img img {
  width: 26px;
  height: 30px;
  cursor: pointer;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

.team-file-name-word {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
  cursor: pointer;
  color: #3d4757;
}

.team-file-first-time,
.team-file-first-size,
.team-file-size,
.team-file-first-do,
.team-file-time,
.team-file-time,
.team-file-do {
  text-align: center;
}

.team-file-time {
  width: 20%;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  color: #6c748a;
  font-size: 12px;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-file-size {
  width: 15%;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  color: #6c748a;
  font-size: 12px;
}

.team-file-do {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.team-file-collection {
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin-right: 20px;
  float: right;
  cursor: pointer;
}

.team-file-collection img {
  width: 20px;
  height: 20px;
}

.caozuo-box-team {
  width: 40%;
}

.caozuo-box {
  display: flex;
}

.team-file-item .caozuo-box .file-more .team-file-collection {
  clear: both;
}

.file-more {
  display: flex;
}

.team-file-do div:not(:first-child) {
  margin-left: 10px;
  cursor: pointer;
}

.teamfile_list_no_box {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
}

.teamfile_list_no_box img {
  width: 110px;
  height: 110px;
}

.teamfile_list_no_th {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  padding-top: 10px;
  padding-bottom: 30px;
}

.teamfile_list_no_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666666;
  padding-bottom: 30px;
}

#team_nofile_upload_btn {
  width: 140px;
  height: 42px;
  line-height: 42px;
  /* background-image: linear-gradient(129deg,
							#4383f0 0%,
							#50acf9 100%); */
  background-color: #4383f0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.team-file-list {
  height: calc(100% - 60px);
  overflow: auto;
}

.team-file-lists {
  height: calc(100% - 185px);
  overflow: hidden;
  margin-right: 14px;
  min-width: 738px;
}

.team-file-head {
  display: none;
}

.teamfile_list_no {
  height: 100%;
}

// 群列表右键
.team-menu-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 160px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.menu-item {
  width: 145px;
  height: 26px;
  line-height: 26px;
  text-indent: 16px;
  margin-top: 9px;
  margin-bottom: 11px;
  margin-left: 8px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.menu-item:hover {
  color: #4383f0;
  background-color: #f0f4fc;
}
</style>