<template>
  <el-dialog
    title="移动文件"
    :visible.sync="$store.state.is_show_move_dwg_box"
    width="780px"
    :modal="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="move_file_box">
      <div class="move_file_main">
        <div class="move_file_main_title" style="margin-bottom: 15px">
          请选择文件夹：<span>{{cur_checked_move_path}}</span>
        </div>
        <el-tree
          :data="cloud_data"
          :props="defaultProps"
          accordion
          :default-expand-all="true"
          @node-click="handleCloudClick"
        >
        </el-tree>
        <!-- <div
          class="move_file_main_first"
          id="move_file_show_team"
          data-active="0"
        >
          <img src="@/assets/images/team/cc-right.png" />
          <span>我的群</span>
        </div> -->
        <!-- <div class="move_file_lists" id="move_file_team_lists"></div> -->

        <div class="team-jion-foot" id="move_file_foot">
          <div
            class="team-jion-foot-left team-jion-foot-item"
            id="move_file_addfile"
            @click="show_addDir_box"
          >
            新建文件夹
          </div>
          <div class="team-jion-foot-right">
            <div
              class="team-jion-foot-item quxiao_btn_hover"
              id="move_file_exit"
              @click="dwg_add_btn_no"
            >
              取消
            </div>
            <div
              class="team-jion-foot-item move_file_isShow"
              id="move_file_exit_submit"
              @click="dwg_add_btn_yes"
            >
              确定
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getDirList, get_teams, createDirectory, move_cloud_file, move_team_file, cloudlink_upddir } from "@/api/get_cloud";
import utils from "@/utils/utils";

export default {
  name: "MovefileView",
  data() {
    return {
      cloud_data: [
        {
          label: "我的云图纸",
          routeName: "/",
          children: [],
        },
        {
          label: "我的群",
          routeName: "/",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      cur_checked_move_path: '',
      cur_checked_item: '',
      is_dir_pass: true,
      cur_move_file_data: ''
    };
  },
  mounted() {
    var that = this;
    this.$EventBus.$off("getDirLists");
    this.$EventBus.$on("getDirLists", function (data) {
      // 开始调用方法
      that.cur_move_file_data = JSON.parse(data)
      that.getDirLists();
    });
  },

  methods: {
    getDirLists() {
      var send = {
        p: "/",
      };
      var newArr = [
        {
          label: "我的云图纸",
          routeName: "/",
          children: [],
          tid:undefined
        },
        {
          label: "我的群",
          routeName: "群组",
          tid: 0,
          children: [],
        },
      ];
      getDirList(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var arr = utils.pathToTree(data.datas);
          newArr[0].children = arr;
          this.cloud_data = newArr;
          if(this.cur_move_file_data.is_share == 1 || this.cur_move_file_data.file_type == '3d') {
            this.cloud_data.splice(1,1)
            return
          }
          this.getTeams()
        }
      });

    },

    getTeams() {
        get_teams().then((res) => {
        var data1 = JSON.parse(res.data);
        if (data1.code == 1) {
          var team_items = data1.team_datas;
          team_items.forEach(item => {
            this.cloud_data[1].children.push(
               {
                label: item.tname,
                routeName: "/",
                tid: item.t_id
              }
            )
          });
        }
        
      });
    },

    handleClose() {
      this.cur_checked_move_path = ''
      this.is_dir_pass = true
      this.cur_checked_item = ''
    },

    handleCloudClick(data) {
      if (data.tid == undefined) {
        this.is_dir_pass = false
      } else {
        this.is_dir_pass = true
      }
      this.cur_checked_move_path = data.routeName
      this.cur_checked_item = data
      this.set_cur_mubiao_movepath(data)
    },


    set_cur_mubiao_movepath(data) {
      var text = ''
      if (data.tid != undefined) {
        text = '群组 「' + data.label + '」'
        if (data.label == '我的群') {
          text = ''
        }
      } else {
        text = this.cur_checked_move_path.slice(1);
        if (text.length > 0) {
          text =   '云盘 「' + '/' + text + '」';
        } else {
          text = '云盘 「' + '我的云图纸' + '」';
        }
      }
      this.cur_checked_move_path = text;
    },
    

    dwg_add_btn_no() {
      this.cur_checked_move_path = ''
      this.$store.state.is_show_move_dwg_box = false;
      this.is_dir_pass = true
    },

    dwg_add_btn_yes() {
      
      var send;
      var cur_item = this.cur_checked_item
      var cur_move_file_data = this.cur_move_file_data
      var path = cur_item.routeName == '/' ? '/' + cur_move_file_data.name : cur_item.routeName + '/' + cur_move_file_data.name

      if (cur_move_file_data.is_share == 1) {
        this.cloudlink_file_move (cur_move_file_data)
        return
      }

      if (this.cur_checked_move_path == '') {
        this.$msg.whiteerr('您还未选择文件夹或群组')
        return
      }
      if (this.is_dir_pass) {
        //群组
        var paths = []
        paths.push(path)
        send = {
          "t_id": cur_item.tid,
          "paths": JSON.stringify(paths)
        }
        move_team_file(send).then((res)=>{
          var data = JSON.parse(res.data)
          if (data.code == 1) {
            this.$msg.white('已成功移动文件至群组')
            this.reset_move_file_action()
          }
        })
      } else {
        //云盘
         path = cur_item.routeName
         send = {
          "from_dir": cur_move_file_data.parent_dir,
          "to_dir": path,
          "filename": cur_move_file_data.name,
        }

        move_cloud_file(send).then((res)=>{
          var data = JSON.parse(res.data)
          if (data.code == 1) {
            this.$msg.white('已成功移动文件')
            this.reset_move_file_action()
            this.$EventBus.$emit("getMyTableData");
            if (this.$parent.is_search_file) {
              this.$parent.getAllFileList()
              this.$parent.updateChangeSeachInput()
            }
          } else if (data.code == 2) {
            this.$msg.whiteerr('已存在同名目录或文件')
          } else if (data.code == 3) {
            this.$msg.whiteerr('该文件不存在或已被删除')
          }
        })
      }

    },

    cloudlink_file_move(cur_move_file_data) {
      if (this.cur_checked_move_path == '') {
        this.$msg.whiteerr('您还未选择文件夹')
        return
      }
       let dir = this.cur_checked_item.routeName

       let send = {
        usertoken: this.$store.state.userinfo.user_token,
        link_id: cur_move_file_data.link_id,
        dir: dir,
      }

      cloudlink_upddir(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          this.$msg.white('已成功移动文件')
          this.reset_move_file_action()
          this.$EventBus.$emit("getMyTableData");
          if (this.$parent.is_search_file) {
            this.$parent.getAllFileList()
          }
        } else if (data.code == 22) {
          this.$msg.whiteerr('已存在同名目录或文件')
        }
      })

      
    },

    //重置组件状态
    reset_move_file_action () {
      this.cur_checked_move_path = ''
      this.$store.state.is_show_move_dwg_box = false;
      this.is_dir_pass = true
    },


    show_addDir_box() {
      if (this.is_dir_pass) {
        this.$msg.whiteerr('必须在我的云图纸中创建新的文件夹')
        return
      }

      this.$prompt("", "新建文件夹", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: '新建文件夹',
        closeOnClickModal: false,
        inputPattern: /^((?![\\|\\ \\"\\?\\>\\<\\:\\.\\'\\/\\*\\]).)*$/,
        inputErrorMessage: "不能使用特殊字符或空格",
      })
        .then(({ value }) => {
          if (value == "" || value == null) {
            this.$msg.whiteerr('文件夹名称不能为空')

            return;
          }
          this.show_addDir(value);
        })
        .catch(() => {
          
        });
    },

    show_addDir(new_dir_name) {
      
      var cur_checked_item = this.cur_checked_item
      var send;
      if (cur_checked_item.t_id == undefined) {
        send = {
          p: cur_checked_item.routeName == '/' ? '/' + new_dir_name : cur_checked_item.routeName + '/' + new_dir_name
        }
      }
      createDirectory(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$msg.white('新建成功')
          this.getDirLists();
        } else if (data.code == 2) {
          this.$msg.whiteerr('已有该名称~')
        } else if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录')
        } else {
          this.$msg.whiteerr('创建失败，请稍后在试')
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-dialog .el-dialog__header {
  position: relative;
  padding: 20px;
}

/deep/ .el-dialog .el-dialog__header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 740px;
  height: 1px;
  background-color: #e7e7e7;
}

.move_file_box {
  position: relative;
  display: block;
  border-radius: 0;
  box-shadow: none;
}

.move_file_main {
  margin-top: 20px;
  width: 740px;
  margin: auto;
}

.move_file_main_title {
  font-size: 15px;
  color: #333;
  padding-top: 30px;
  user-select: none;
}

.move_file_main_title span {
  color: blue;
}

.move_file_main_first {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  height: 32px;
  line-height: 32px;
  /* background-color: #f0f4fc; */
  margin-top: 14px;
}

.move_file_main_first span {
  padding-left: 8px;
  font-size: 15px;
  color: #999;
  user-select: none;
}

.move_file_lists {
  max-height: 150px;
  overflow: auto;
}

.move_file_item {
  font-size: 14px;
  color: #999;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  padding-left: 18px;
}

.move_file_item:hover {
  background-color: #f5f5f5;
}

.move_file_item_checked {
  background-color: #f0f4fc;
}

.move_file_item span {
  padding-left: 8px;
  user-select: none;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.move_file_team_item img {
  width: 20px;
  height: 20px;
  opacity: 0;
}

.move_file_team_item {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.move_file_team_item span {
  padding-left: 6px;
}

#move_file_addfile {
  border: 1px solid #ced0d1;
  color: #999;
  padding: 0 20px;
}

#move_file_addfile:hover {
  color: #4383f0;
  border: solid 1px #4383f0;
}

.team-jion-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.team-jion-foot-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

#move_file_foot {
  width: auto;
  margin: unset;
  padding: 30px 20px 20px 20px;
  user-select: none;
}

.team-jion-foot-item {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

#move_file_exit {
  border: 1px solid #ced0d1;
  color: #999;
  cursor: pointer;
}

#move_file_exit_submit {
  background-color: #4383f0;
  color: #fff;
  margin-left: 10px;
}

#move_file_team_lists {
  display: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#move_file_exit:hover {
  color: #4383f0;
  border: solid 1px #4383f0;
}

.move_file_isShow {
  cursor: pointer;
  opacity: 1;
}

.move_file_isShow:hover {
  opacity: 0.8;
}
</style>